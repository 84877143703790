<template>
  <div class="trip-info-col">
    <div class="trip--map" v-if="tabActive !== 4">
      <GMapMap :center="center" :options="options" :zoom="5" map-type-id="terrain" style="height: 200px">
        <GMapMarker :key="index" v-for="(m, index) in markers" :position="m.position"> </GMapMarker>
      </GMapMap>
    </div>

    <ul id="Tab--trip--info" class="nav nav-tabs nav-justified" role="tablist">
      <li :class="tabActive == 1 && 'active'" @click="handleChangeTab(1)">
        <a href="#">
          <i class="icon-abui-file" /><span>{{ $t('trip_item.right_menu.tab_name_file') }}</span>
        </a>
      </li>
      <li :class="tabActive == 2 && 'active'" @click="handleChangeTab(2)">
        <a href="#">
          <i class="icon-abui-checklist" /><span>{{ $t('trip_item.right_menu.tab_name_to_do') }}</span>
        </a>
      </li>
      <li :class="tabActive == 3 && 'active'" @click="handleChangeTab(3)">
        <a href="#">
          <i class="icon-abui-bookmark" /><span>{{ $t('trip_item.right_menu.tab_name_clip') }}</span>
        </a>
      </li>
      <li v-if="isShowExpenseItem" :class="tabActive == 4 && 'active'" @click="handleChangeTab(4)">
        <a href="#">
          <i class="icon-abui-jpy" />
          <span>
            {{ $t('trip_item.right_menu.tab_name_cost') }}
          </span>
        </a>
      </li>
    </ul>

    <div id="Tab--trip--infoContent" class="tab-content">
      <div class="tab-pane tab-file fade" :class="tabActive == 1 && 'active in'">
        <div class="info-list-wrap">
          <ul class="trip--info--file-list">
            <li v-for="item in listFileByTrip" :key="item.id">
              <a :href="item.link" target="_blank" v-if="item.name && item.content_reservation === ''">
                <i class="icon-abui-file" v-if="item.name"/>
                  <span class="file--name" >{{ trimNameFileOnly(item) }}</span>
                <span class="file--date">{{ showDateTime(item.created_at, 'YYYY/MM/DD') }}</span>
              </a>
              <a @click="openModalEdit(item)" v-else>
                <i class="icon-abui-file" v-if="item.name"/>
                <span style="width: 33px" v-if="item.content_reservation && item.name == undefined"> </span>
                <span class="file--name" v-if="item.name && item.content_reservation">{{ trimNameFile(item) }}  |  {{ item.content_reservation }}</span>
                <span class="file--name" v-else>{{ item.content_reservation }}</span>
                <span class="file--date">{{ showDateTime(item.created_at, 'YYYY/MM/DD') }}</span>
              </a>
              <button v-if="showAddButton && !checkDisableEdit" class="btn btn-sm btn-flat-danger del-img" @click="openModalDelete(item)">
                <i class="icon-abui-cross" style="font-size: 14px" />
              </button>
            </li>
          </ul>
          <div v-if="showAddButton && !checkDisableEdit" class="detail-foot">
            <button
                class="btn btn-flat-primary btn-sm"
                :disabled="isOperatorOrAdmin"
                @click="showModalUploadFile = true"
            >
              <i class="icon-abui-plus prx" />{{ $t('trip_item.right_menu.btn_add_file') }}
            </button>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" :class="tabActive == 2 && 'active in'">
        <TripTodo
            ref="TripTodo"
            :resetTodo="tabActive"
            :tripData="tripData"
            :showButtonCreate="showAddButton"
            :checkDisableEdit="checkDisableEdit"
            @setCenterMap="setCenterMap"
            @activeTabTodo="tabActive = 2"
            @onChangeData="onChangeData"
        />
      </div>

      <div class="tab-pane fade" :class="tabActive == 3 && 'active in'">
        <Clip
            ref="Clip"
            :resetClip="tabActive"
            :showButtonCreate="showAddButton"
            :checkDisableEdit="checkDisableEdit"
            :tripData="tripData"
            @setCenterMap="setCenterMap"
            @onChangeData="onChangeData"
        />
      </div>

      <div class="tab-pane fade" :class="tabActive == 4 && 'active in'">
        <div class="plan-status expense-status4 show--status90" style="display: none">
          <span class="status-text">
            {{ $t('trip_item.right_menu.settlement_approved') }}
            <i class="icon-abui-check-inverted plx" />
          </span>
        </div>

        <div class="info-list-wrap">
          <ManualInput
              ref="ManualInput"
              v-if="showManualInput"
              :isCreateMode="isCreateMode"
              @close="validateCost"
              @open-modal-upload-file="showModalUploadCost = true"
              :costId="costId"
              :order="order"
              :tripId="tripId"
              :tripTime="tripTime"
              :checkDisableEdit="checkDisableEdit"
              @onChangeData="onChangeData"
              @onRelaodBtnShow="getCostByGroup"
          />
          <div v-else>
            <Cost
                @create-cost="createCost"
                @update-cost="updateCost"
                @reloadTripDetail="reloadCostData"
                :isReloadCost="isReloadCost"
                :tripData="tripData"
                :resetCost="tabActive"
                :showAddCost="showAddButtonCost"
                :checkDisableEdit="checkDisableEdit"
                :list-cost-item-error="listCostItemError"
            />
          </div>
          <div v-if="(showAddButtonCost && !checkDisableEdit) || showButtonSettlementOfExpenses" class="detail-foot">
            <div v-if="showAddButtonCost && !checkDisableEdit" class="btn-group">
              <button
                  type="button"
                  class="btn btn-flat-primary btn-sm dropdown-toggle"
                  @click="isAddCost = !isAddCost"
                  v-click-outside="
                  () => {
                    isAddCost = false;
                  }
                "
              >
                <i class="icon-abui-plus prx" />{{ $t('trip_item.right_menu.btn_add_cost') }}
                <span class="caret" />
              </button>
              <ul v-if="isAddCost" class="dropdown-menu" role="menu" style="top: unset; bottom: 40px">
                <li @click="showModalUploadCost = true" v-if="isShowUploadReceiptCost">
                  <a>
                    {{ $t('trip_item.right_menu.upload_receipt_card_invoice') }}
                  </a>
                </li>
                <li @click="showTransportation = true">
                  <a class="js-btn-addnew-info-item--transport">
                    {{ $t('trip_item.right_menu.enter_the_transportation_cost') }}
                  </a>
                </li>
                <li class="divider"></li>
                <li
                    @click="
                    () => {
                      showManualInput = true;
                      isCreateMode = true;
                      isAddCost = false;
                      order = null;
                    }
                  "
                >
                  <a class="js-btn-addnew-info-item">{{ $t('trip_item.right_menu.manual_input') }}</a>
                </li>
              </ul>
            </div>
            <button
              v-if="showButtonSettlementOfExpenses && !hiddenBtnSendExpense"
              @click="checkShowModalSettlementExpense"
              class="btn btn-primary btn-sm show--status31 show--status90 send-expense-request"
            >
              {{ $t('trip_item.right_menu.settlement_of_expenses') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalUploadFile
      :dialogVisible="showModalUploadFile"
      @cancel="showModalUploadFile = false"
      @confirm="confirmUploadFile"
      :isUploadWithNote="true"
      :title="$t('trip_update.file_upload')"
  />
  <ModalUploadFileEdit
      :dialogVisible="showModalUploadFileEdit"
      :itemFileEdit="itemFileEdit"
      @cancel="showModalUploadFileEdit = false"
      @confirm="confirmUpdateFileInput"
      :isUploadWithNote="true"
      :title="$t('trip_update.file_upload_edit')"
  />
  <ModalUploadFile
      :dialogVisible="showModalUploadCost"
      :acceptImageAndPDFOnly="true"
      :isSingleUpload="true"
      accepted-files="image/jpg, image/jpeg, image/png, .heif, application/pdf"
      @cancel="showModalUploadCost = false"
      @confirm="confirmUploadCost"
      :title="$t('trip_item.right_menu.upload_receipt_or_card_or_invoice')"
      :checkShowModal="true"
  />
  <ModalTransportation
      :dialogVisible="showTransportation"
      @cancel="showTransportation = false"
      @success="confirmUploadTransportation"
  />
  <ModalSettlementExpense
      :dialogVisible="showSettlementExpense"
      :destination-type="tripData.destination_type"
      @cancel="showSettlementExpense = false"
      @success="sendSuccess"
  />
  <ModalConfirm
      :dialogVisible="showBoxConfirm"
      @cancel="showBoxConfirm = false"
      @yes="handleDelete()"
      :title="$t('Q-CM-104')"
  />
</template>
<script>
import ModalConfirm from '@/components/common/ModalConfirm';
import TripTodo from '@/components/trip/trip-item/Todo';
import Clip from '@/components/trip/trip-item/Clip';
import Cost from '@/components/trip/trip-item/Cost';
import ModalUploadFile from '@/components/common/ModalUploadFile';
import ModalUploadFileEdit from '@/components/common/ModalUploadFileEdit';
import ModalTransportation from '@/components/common/ModalTransportation';
import ModalSettlementExpense from '@/components/common/ModalSettlementExpense';
import ManualInput from '@/components/common/ManualInput';
import vClickOutside from 'click-outside-vue3';
import mixins from '@/helpers/mixins';
import moment from 'moment';

export default {
  name: 'Trip-Detail-Right',
  emits: ['onChangeData', 'reloadTripDetailInfo'],
  mixins: [mixins],
  components: {
    TripTodo,
    ModalUploadFile,
    ModalUploadFileEdit,
    ModalTransportation,
    ModalSettlementExpense,
    ModalConfirm,
    Clip,
    Cost,
    ManualInput,
  },
  props: {
    tripTime: {
      type: Object,
      default: () => {},
    },
    tripDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: {
        disableDefaultUI: true,
        minZoom: 4,
      },
      tabActive: this.$store.state.auth?.user?.setting.is_show_expense_item ? 4 : 3,
      hiddenBtnSendExpense: false,
      listFileByTrip: [],
      showModalUploadFile: false,
      showModalUploadFileEdit: false,
      showModalUploadCost: false,
      showTransportation: false,
      showSettlementExpense: false,
      isAddCost: false,
      showManualInput: false,
      errors: {
        payable: '',
      },
      currencyList: [],
      listSchedule: [],
      listCostItem: [],
      listPaymentMethod: [],
      listTaxClassification: [],
      costId: {},
      itemFileEdit: {},
      itemFileDelete: {},
      showBoxConfirm: false,
      order: null,
      isCreateMode: true,
      tripData: {},
      listCostItemError: [],
      costs: [],
      center: {
        lat: 35.672855,
        lng: 139.817413,
      },
      isReloadCost: false,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  created() {
    this.startLoading();
    Promise.all([this.getFilesByTrip(), this.getCostByGroup(), this.getDefaultCenterMap()]).then(() => {
      // this.endLoading();
    });
  },
  mounted() {
    this.$emitter.on('getStatusSendRequest', val => {
      if (val === 1 && !this.isShowSettingApproval && this.showApprovalExpense) {
        this.showSettlementExpense = true;
      }
    });
    this.$emitter.on('checkShowButtonSettlementOfExpenses', data => {
      this.tripData = data;
    });
    this.$emitter.on('activeTodo', todoId => {
      if (todoId == 0) {
        this.$services.trip_item.getCostByGroup(
            this.tripId,
            res => {
              this.costs = res.costs;
              if ((this.checkEmpty(res.costs) || this.checkEmpty(res.total_cost_without_sb)) && this.checkEmpty(res.input_cost)) {
                this.hiddenBtnSendExpense = true;
              } else {
                this.hiddenBtnSendExpense = false;
              }
            },
            () => {},
        );
      }
    });
    this.$emitter.on('updateCostAfterCreatingSchedule', () => {
      this.getCostByGroup();
    });
    this.$emitter.on('reloadInsuranceList', () => {
      this.getFilesByTrip();
    });
  },
  beforeUnmount() {
    this.$emitter.off('getStatusSendRequest');
    this.$emitter.off('checkShowButtonSettlementOfExpenses');
    this.$emitter.off('updateCostAfterCreatingSchedule');
    this.$emitter.off('activeTodo');
    this.$emitter.off('reloadInsuranceList');
  },
  watch: {
    companyAddress() {
      this.getDefaultCenterMap();
    },
    tripDetail(newVal) {
      this.tripData = newVal;
    },
    tabActive(val) {
      if (val == 1) {
        this.getFilesByTrip();
      }
    },
    markers(newVal) {
      if (!this.checkEmpty(newVal)) {
        this.setCenterMap(newVal[0].position);
        return;
      }
    },
    isShowExpenseItem(newValue) {
      this.tabActive = newValue ? 4 : 3;
    }
  },
  computed: {
    isShowUploadReceiptCost() {
      return this.$store.state.auth?.user?.setting.is_upload_receipt_cost == 1 || false;
    },
    showApprovalExpense() {
      return this.$store.state.auth?.user?.setting?.is_feature_payment || false;
    },
    isShowSettingApproval() {
      return this.$store.state.auth?.user?.setting.is_show_approval || false;
    },
    isShowSettingReport() {
      return this.$store.state.auth?.user?.setting.is_show_report || false;
    },
    isShowExpenseItem() {
      return this.$store.state.auth?.user?.setting.is_show_expense_item || false;
    },
    isUploadInvoice() {
      return this.$store.state.auth?.user?.setting.is_upload_invoice == 1 || false;
    },
    companyAddress() {
      return this.$store.state.auth?.user?.company?.address || '';
    },
    markers() {
      return this.$store.state.map.markers;
    },
    tripId() {
      return this.$route.params.id;
    },
    showAddButton() {
      return (
          (![
            'report_approved',
            'report_cancel',
            'expense_waiting',
            'expense_pending',
            'expense_approved',
            'expense_rejected',
          ].includes(this.tripCode) && this.tripUserId == this.userId && this.tripData?.trip_status !== 3) ||
          (
              this.tripData?.trip_status === 3 && this.tripUserId == this.userId && !this.isShowSettingApproval && this.showApprovalExpense &&
              ['expense_no_applied', 'expense_rejected', 'expense_canceled'].includes(this.tripData?.expense_settlement?.code)
          )
      );
    },
    showAddButtonCost() {
      return (
          !['report_cancel', 'expense_waiting', 'expense_pending', 'expense_approved'].includes(this.tripCode) &&
          this.tripUserId == this.userId
      );
    },
    tripUserId() {
      return this.tripData?.user_id || '';
    },
    checkDisableEdit() {
      return (
        this.isOperatorOrAdmin ||
        [
          'approval_waiting',
          'approval_pending',
          'report_waiting',
          'report_pending',
          'report_approved',
          'report_cancel',
          'expense_waiting',
          'expense_pending',
          'expense_approved',
        ].includes(this.tripCode) || (this.tripData?.trip_status === 3 && (this.isShowSettingApproval || ['expense_waiting', 'expense_pending', 'expense_approved'].includes(this.tripData?.expense_settlement?.code))) ||
      this.tripUserId != this.userId
      ||
      (this.isTripStartTraveling() && this.isShowSettingApproval && !this.isShowSettingReport  && !this.showApprovalExpense)
      );
    },
    showButtonSettlementOfExpenses() {
      return (
          this.$store.state.auth?.user?.setting.is_feature_payment == 1 &&
          ['report_approved', 'expense_no_applied', 'expense_rejected'].includes(this.tripCode) &&
          this.tripUserId == this.userId
      );
    },
    tripCode() {
      if (!this.checkEmpty(this.tripData?.expense_settlement?.code)) {
        return this.tripData?.expense_settlement?.code;
      }

      if (!this.checkEmpty(this.tripData?.report_status?.code)) {
        return this.tripData?.report_status?.code;
      }

      return this.tripData?.approval_status?.code || false;
    },
  },
  methods: {
    handleDelete() {
      this.startLoading();
      this.$services.input.deleteInput(
          this.itemFileDelete.input_id,
          () => {
            this.endLoading();
            this.notifySuccess(this.$t('I-CM-102'));
            this.showBoxConfirm = false;
            this.$emitter.emit('activeTodo', 0);
            this.itemFileDelete = {};
            this.getFilesByTrip();
          },
          err => {
            this.endLoading();
            this.showBoxConfirm = false;
            this.notifyError(this.$t(err.error));
          },
      );
    },
    trimNameFile(val) {
      let textName = val.name;
      if (val.name && val.content_reservation) {
        if (val.name.length > 18 && val.content_reservation.length > 16) {
          textName = textName.substring(0, 18) + '...';
        }
      }
      return textName;
    },
    trimNameFileOnly(val) {
      let textName = val.name;
      if (textName && textName.length > 25) {
        textName = textName.substring(0, 25) + '...';
      }
      return textName;
    },
    reloadCostDetail() {
      this.$refs.ManualInput.reloadData();
    },
    openModalEdit(item) {
      this.showModalUploadFileEdit = true;
      this.itemFileEdit = item;
    },
    openModalDelete(item) {
      this.showBoxConfirm = true;
      this.itemFileDelete = item;
    },
    onChangeData(val) {
      this.$emit('onChangeData', val);
    },
    async getDefaultCenterMap() {
      if (this.companyAddress) {
        await this.$services.common.searchTimeZones(
            { address: this.companyAddress },
            res => {
              if (res) this.setCenterMap(res);
            },
            () => {},
        );
      }
    },
    setCenterMap(data) {
      if (data.lat & data.lng) {
        this.center = {
          lat: +data.lat + Math.random() * 0.00001,
          lng: +data.lng,
        };
      }
    },
    async loadShowBtnSendRequestExpense() {
      this.showSettlementExpense = true;
    },
    async checkShowModalSettlementExpense() {
      if (!this.isShowSettingApproval && this.showApprovalExpense) {
        await this.$emitter.emit('checkCanSendRequest', 1);
        console.log('checkCanSendRequest startr');
      }
      this.listCostItemError = [];
      let flgValidate = true;

      this.costs.forEach(cost => {
        cost.detail.forEach(item => {
          if (item.is_sb == 0) {
            if (
                this.checkEmpty(item.title) ||
                this.checkEmpty(item.company_cost_item_id) ||
                (this.isUploadInvoice && this.checkEmpty(item.file) && item.type_cost != 3 && item.is_sb == 0) ||
                this.checkEmpty(item.company_cost_item_name) ||
                this.checkEmpty(item.payment_date) ||
                this.checkEmpty(item.payment_method) ||
                item.flag_temporary_cost === 1 ||
                item.is_company_cost_item_valid === 0
            ) {
              flgValidate = false;
              this.listCostItemError.push(item.id);
            } else {
              if (this.listCostItemError.includes(item.id))
                this.listCostItemError.splice(this.listCostItemError.indexOf(item.id), 1);
            }
          }
        });
      });

      if (!flgValidate) {
        this.notifyError(this.$t('E-CU-128'));
        return;
      }
      if (this.isShowSettingApproval && this.showApprovalExpense) {
        this.showSettlementExpense = true;
      }
    },
    //Use for submit request button on UpdateTrip.vue
    //requestType = 1 (Approval), requestType = 2 (Report)
    validateExpenses() {
      let flgValidate = true;
      this.costs.forEach(cost => {
        cost.detail.forEach(item => {
            if (
                this.checkEmpty(item.title) ||
                this.checkEmpty(item.company_cost_item_id) ||
                (this.isUploadInvoice && this.checkEmpty(item.file) && item.type_cost != 3 && item.is_sb == 0) ||
                this.checkEmpty(item.company_cost_item_name) ||
                this.checkEmpty(item.payment_method) ||
                item.is_company_cost_item_valid === 0
            ) {
              flgValidate = false;
              if (!this.listCostItemError.includes(item.id)) this.listCostItemError.push(item.id);
            } else {
              if (this.listCostItemError.includes(item.id))
                this.listCostItemError.splice(this.listCostItemError.indexOf(item.id), 1);
            }
        });
      });

      if (!flgValidate) {
        this.$emitter.emit('syncBusinessDialogVisible', 0);
        this.$emitter.emit('syncButtonCreateRequestVisible', 0);
        return;
      }
      this.$emitter.emit('syncBusinessDialogVisible', 1);
      this.$emitter.emit('syncButtonCreateRequestVisible', 1);
    },
    validateCost() {
      this.showManualInput = false;
      this.order = null;
      this.listCostItemError = [];
      // this.getCostByGroup();
      // this.costs.forEach(cost => {
      //   cost.detail.forEach(item => {
      //     if (
      //       this.checkEmpty(item.title) ||
      //       this.checkEmpty(item.company_cost_item_id) ||
      //       this.checkEmpty(item.company_cost_item_name) ||
      //       this.checkEmpty(item.payment_method)
      //     ) {
      //       if (!this.listCostItemError.includes(item.id)) this.listCostItemError.push(item.id);
      //     } else {
      //       if (this.listCostItemError.includes(item.id))
      //         this.listCostItemError.splice(this.listCostItemError.indexOf(item.id), 1);
      //     }
      //   });
      // });
      this.$services.trip_item.getCostByGroup(
          this.tripId,
          res => {
            this.costs = res.costs;
            this.costs.forEach(cost => {
              cost.detail.forEach(item => {
                if (
                    (this.checkEmpty(item.title) ||
                        this.checkEmpty(item.company_cost_item_id) ||
                        this.checkEmpty(item.company_cost_item_name) ||
                        this.checkEmpty(item.payment_method) ||
                        item.is_company_cost_item_valid === 0) && item.flag_temporary_cost === 0
                ) {
                  if (!this.listCostItemError.includes(item.id)) this.listCostItemError.push(item.id);
                } else {
                  if (this.listCostItemError.includes(item.id))
                    this.listCostItemError.splice(this.listCostItemError.indexOf(item.id), 1);
                }
              });
            });
          },
          () => {
            // this.notifyError(this.$t(err.error));
          },
      );
    },
    async getCostByGroup() {
      await this.$services.trip_item.getCostByGroup(
        this.tripId,
        res => {
          this.costs = res.costs;
          if ((this.checkEmpty(res.costs) || this.checkEmpty(res.total_cost_without_sb)) && this.checkEmpty(res.input_cost)) {
            this.hiddenBtnSendExpense = true;
          } else if (this.showApprovalExpense && !this.isShowSettingApproval) {
            this.hiddenBtnSendExpense = false;
          }
        },
        () => {
          // this.notifyError(this.$t(err.error));
        },
      );
    },
    sendSuccess() {
      this.showSettlementExpense = false;
      this.$emit('reloadTripDetailInfo');
      // this.$emitter.emit('activeTodo', 0);
    },
    totalAmount(data) {
      if (!data?.detail) return 0;

      let amount = 0;
      for (let index = 0; index < data.detail.length; index++) {
        const element = data.detail[index];

        amount += +element.amount;
      }

      return amount;
    },
    handleChangeTab(index) {
      this.tabActive = index;
      if (this.tabActive == 2) this.$refs.TripTodo.getListTodos();
      if (this.tabActive == 3) this.$refs.Clip.getListClips();
    },
    async getFilesByTrip() {
      await this.$services.trip.getFilesByTrip(
          { trip_id: this.tripId },
          res => {
            this.listFileByTrip = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    confirmUploadFile(files, content_reservation) {
      let formData = new FormData();
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          formData.append('files[]', files[index]);
        }
      }
      formData.append('trip_id', this.tripId);
      formData.append('content_reservation', content_reservation);
      this.startLoading();
      this.$services.trip.uploadFile(
          formData,
          () => {
            this.endLoading();
            this.showModalUploadFile = false;
            this.notifySuccess(this.$t('trip_update.upload_file_done'));
            this.$emitter.emit('activeTodo', 0);
            this.getFilesByTrip();
          },
          () => {
            this.endLoading();
            this.notifyError(this.$t('E-CU-011'));
          },
      );
      // } else {
      //   this.notifyError(this.$t('E-CM-100', { item: this.$t('common.file') }));
      // }
    },
    confirmUpdateFileInput(params) {
      this.$services.input.updateInput(
          params.input_id,
          { content_reservation: params.content_reservation },
          () => {
            setTimeout(() => {
              this.endLoading();
              this.showModalUploadFileEdit = false;
              this.notifySuccess(this.$t('trip_update.edit_success'));
              this.$emitter.emit('activeTodo', 0);
              this.itemFileEdit = {};
              this.getFilesByTrip();
            }, 100);
            this.flgSubmit = false;
          },
          err => {
            this.flgSubmit = false;
            this.notifyError(this.$t(err.error), true);
          },
      );
    },
    confirmUploadCost(files) {
      this.showModalUploadCost = false;
      let formData = new FormData();
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          formData.append('files[]', files[index]);
        }
        formData.append('trip_id', this.tripId);
        this.startLoading();
        this.$services.trip_item.uploadCost(
            formData,
            () => {
              this.endLoading();
              this.notifySuccess(this.$t('trip_update.upload_file_done'));
              this.showModalUploadCost = false;
              this.isReloadCost = true;
            },
            err => {
              this.endLoading();
              if (!this.checkEmpty(err.error['files.0'][0])) {
                this.notifyError(this.$t(err.error['files.0'][0]));
              } else {
                this.notifyError(this.$t(err.error));
              }
            },
        );
      } else {
        this.notifyError(this.$t('E-CM-101', { name: this.$t('common.file') }));
      }
    },
    confirmUploadTransportation() {
      this.showTransportation = false;
      this.$emitter.emit('checkShowButtonSettlementOfExpenses', this.tripData);
      this.getCostByGroup();
    },
    createCost(order) {
      this.isCreateMode = true;
      this.order = order;
      this.showManualInput = true;
    },
    reloadCostData() {
      this.getCostByGroup();
      this.$emit('reloadTripDetailInfo');
    },
    updateCost(cost) {
      this.isCreateMode = false;
      this.costId = cost.id;
      this.showManualInput = true;

      if (this.tripData?.trip_status === 2 && this.tripData?.report_status?.id === 6 && !cost.is_sb) {
        this.notifyError('出張報告承認後の費用追加・修正はできません。費用タブから新規追加してください。');
      }
    },
    isTripStartTraveling() {
      if (!this.checkEmpty(this.tripData.start_time) && this.tripData?.approval_status_id === 6) {
        if (
            moment(new Date()).format('YYYY-MM-DD') >= moment(this.tripData.start_time).format('YYYY-MM-DD')
        ) {
          return true;
        }
      }
      return false;
    }
  },
};
</script>

<style lang="scss" scoped>
.tab-content {
  height: 100%;
  // overflow: scroll;
}
.tab-file {
  overflow: scroll;
  max-height: calc(100vh - 300px);
}
.dropdown-menu {
  margin: 4px 0px !important;
  opacity: 1;
  visibility: visible;
  cursor: pointer;
}
.detail--wrap.expense--detail {
  overflow: scroll;
  height: calc(100vh - 145px);
}
.file--name {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 313px;
  white-space: nowrap;
  padding-right: 10px;
}
.sortable-handle-transparent {
  display: block;
  z-index: 10;
  position: relative;
  width: 24px;
  min-height: 64px;
  margin-right: -24px;
  background-color: transparent;
}
.send-expense-request {
  margin-right: 0 !important;
  margin-left: auto !important;
}
.del-img {
  margin: 0px !important;
}
</style>
