<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('security.security') }}</h2>
    </div>
  </nav>

  <body class="body-content">
    <el-dialog
      v-model="isShowModalEmergencyContact"
      :show-close="false"
      width="640px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header--title">{{ $t('security.emergency_contact_information') }}</div>
          </div>
          <div class="modal-body">
            <div
              class="js-addcontrol1--wrap mbl"
              v-if="selectedEmergencyContacts?.length && selectedEmergencyContacts.length > 0"
            >
              <div class="row js-addcontrolemergency--1">
                <div class="col-sm-8">
                  <div class="form-group viewonly">
                    <label class="label--name">
                      <span class="label-text">{{ $t('security.name') }}</span>
                    </label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group viewonly">
                    <label class="label--price">
                      <span class="label-text">{{ $t('security.telephone_number') }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div
                v-for="(emergency, index) in selectedEmergencyContacts"
                :key="index"
                class="row js-addcontrolemergency--1"
              >
                <div class="col-sm-8">
                  <div class="form-group viewonly">
                    <p class="viewonly-text input-sm">{{ emergency.full_name }}</p>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group viewonly">
                    <p class="viewonly-text input-sm">{{ emergency.phone }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <p class="no-data">{{ $t('common.no_data') }}</p>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-sm-12">
                <button class="btn btn-sm btn-flat-default btn-block" @click="isShowModalEmergencyContact = false">
                  {{ $t('common.close') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <div class="wrapper-page">
      <div class="wrapper">
        <div class="container-fluid">
          <div v-show="!isExpandMap">
            <div class="trip-info-col bg-gray col-expandable" style="z-index: 1">
              <button class="btn btn-sm btn-flat-default btn-col-expand" @click="isExpandMap = true">
                <i class="icon-abui-expand" />
              </button>
              <div class="security--map hide--new">
                <div class="section noborder" style="height: 800px; padding: 0px !important">
                  <GMapMap
                    v-if="!checkEmpty(listMarker)"
                    :center="!checkEmpty(listMarker[0]?.position) ? listMarker[0]?.position : center"
                    :options="options"
                    :zoom="3"
                    :key="componentKey"
                    map-type-id="terrain"
                    style="height: 100vh"
                  >
                    <div v-show="listMarker.length">
                      <gmap-custom-marker v-for="(marker, index) in listMarker" :key="index" :marker="!checkEmpty(marker.position) ? marker.position : center">
                        <div v-if="marker.answer_type == 2" class="security--map focused">
                          <div class="security--map--people focus done" :class="!checkEmpty(marker.position) && 'gps'">
                            <span class="people--gps" v-if="marker.isGPS">
                              <i class="icon-abui-gps" />
                            </span>
                            <div
                              class="baloon dropshadow--lv2 answer--ok"
                              :style="checkEmpty(marker.answer_message) && 'background-color: #d0d6da'"
                            >
                              <span class="baloon--inner">
                                {{
                                  !checkEmpty(marker.answer_message) ? marker.answer_message : $t('security.no_comment')
                                }}
                              </span>
                            </div>
                            <div class="name--wrap dropshadow--lv1">
                              <span class="name">
                                {{ marker.member_name ? marker.member_name : marker.full_name ? marker.full_name : '' }}
                                <span class="phone--people">{{ marker.phone }}</span>
                              </span>
                              <button class="btn btn-sm btn-flat-default" @click="showEmergencyContact(marker)">
                                <i class="icon-abui-bullhorn" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="marker.answer_type == 3" class="security--map focused">
                          <div
                            class="security--map--people focus done ng"
                            :class="!checkEmpty(marker.position) && 'gps'"
                          >
                            <span class="people--gps" v-if="marker.isGPS">
                              <i class="icon-abui-gps" />
                            </span>
                            <div
                              class="baloon dropshadow--lv2 answer--ok"
                              :style="checkEmpty(marker.answer_message) && 'background-color: #d0d6da'"
                            >
                              <span class="baloon--inner">
                                {{
                                  !checkEmpty(marker.answer_message) ? marker.answer_message : $t('security.no_comment')
                                }}
                              </span>
                            </div>
                            <div class="name--wrap dropshadow--lv1">
                              <span class="name">
                                {{ marker.member_name ? marker.member_name : marker.full_name ? marker.full_name : '' }}
                                <span class="phone--people">{{ marker.phone }}</span>
                              </span>
                              <button class="btn btn-sm btn-flat-default" @click="showEmergencyContact(marker)">
                                <i class="icon-abui-bullhorn" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div v-else class="security--map focused">
                          <div class="security--map--people focus" :class="!checkEmpty(marker.position) && 'gps'">
                            <span class="people--gps" v-if="marker.isGPS">
                              <i class="icon-abui-gps" />
                            </span>
                            <div class="name--wrap dropshadow--lv1">
                              <span class="name">
                                {{ marker.member_name ? marker.member_name : marker.full_name ? marker.full_name : '' }}
                                <span class="phone--people">{{ marker.phone }}</span>
                              </span>
                              <button class="btn btn-sm btn-flat-default" @click="showEmergencyContact(marker)">
                                <i class="icon-abui-bullhorn" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </gmap-custom-marker>
                    </div>
                  </GMapMap>
                  <GMapMap
                    v-else
                    :center="!checkEmpty(targetCountry) ? targetCountry : center"
                    :options="options"
                    :zoom="3"
                    map-type-id="terrain"
                    style="height: 100vh"
                  >
                    <div v-show="listTargetPersonWithMarker.length">
                      <gmap-custom-marker v-for="(marker, index) in listTargetPersonWithMarker" :key="index" :marker="!checkEmpty(marker.position) ? marker.position : center" >
                        <div class="security--map focused">
                          <div class="security--map--people focus" :class="!checkEmpty(marker.position) && 'gps'">
                            <div class="name--wrap dropshadow--lv1">
                              <span class="name">
                                {{ marker.member_name ? marker.member_name : marker.full_name ? marker.full_name : '' }}
                                <span class="phone--people">{{ !checkEmpty(marker.phone) ? marker.phone : '' }}</span>
                              </span>
                              <button class="btn btn-sm btn-flat-default" @click="showEmergencyContact(marker)">
                                <i class="icon-abui-bullhorn" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </gmap-custom-marker>
                    </div>
                  </GMapMap>
                </div>
              </div>
            </div>

            <div class="plan-stage">
              <div class="section noborder" id="">
                <div class="col-md-12" style="padding-left: 0px">
                  <div class="form-group">
                    <label
                      class="checkbox checkbox-inline checkbox-radio-div"
                      :class="typeFilter == this.$t('create_security.from_the_country') && 'checked'"
                    >
                      <span class="icons">
                        <span class="first-icon icon-abui-radio-unchecked" />
                        <span class="second-icon icon-abui-radio-checked" />
                      </span>
                      <input type="radio" :value="this.$t('create_security.from_the_country')" v-model="typeFilter" />
                      <span class="text_type_radio">
                        {{ $t('create_security.from_the_country') }}
                      </span>
                    </label>
                    <label
                      class="checkbox checkbox-inline checkbox-radio-div"
                      :class="typeFilter == this.$t('create_security.from_flight') && 'checked'"
                    >
                      <span class="icons">
                        <span class="first-icon icon-abui-radio-unchecked" />
                        <span class="second-icon icon-abui-radio-checked" />
                      </span>
                      <input type="radio" :value="this.$t('create_security.from_flight')" v-model="typeFilter" />
                      <span class="text_type_radio">
                        {{ $t('create_security.from_flight') }}
                      </span>
                    </label>
                  </div>
                </div>
                <div class="js-radio_item03-01__tgt" v-show="isShowFilterCountry">
                  <div class="row show--edit hide--view">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('common.target_country') }}</span>
                        </label>
                        <MultiOptionSelect
                          ref="ListCountrySelect"
                          :list-data="listCountry"
                          :display-by-locale="true"
                          :isListCountry="true"
                          :showSelectAll="true"
                          :showSelectOther="true"
                          :placeholder="$t('common.please_select_required')"
                          :class="!checkEmpty(errors.target_country) && 'has-validation-error-el'"
                          @handle-selected-params="updateListCountrySelected"
                        />
                        <p role="alert" class="error-feedback" v-text="errors.target_country" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="js-radio_item03-01__tgt" v-show="!isShowFilterCountry">
                  <div class="row">
                    <div class="col-sm-8">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('common.airlines') }}</span>
                        </label>
                        <MultiOptionSelect
                          ref="ListCountrySelect"
                          :list-data="listAirline"
                          :display-by-locale="true"
                          :placeholder="$t('common.please_select_required')"
                          @handle-selected-params="updateListAirlineSelected"
                          :class="!checkEmpty(errors.airline) && 'has-validation-error-el'"
                        />
                        <p role="alert" class="error-feedback" v-text="errors.airline" />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label>
                          <span class="label-text">{{ $t('common.simple_name') }}</span>
                        </label>
                        <el-input type="text" v-model="flightNumber" />
                        <!-- <p role="alert" class="error-feedback" v-text="errors.flight_number" /> -->
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row show--edit hide--view">
                  <div
                    class="col-md-6 col-all-closer col-all-closer--leftend col-sm-closer--rightend col-xs-closer--rightend"
                  >
                    <div class="form-group">
                      <label>
                        <span class="label-text">
                          {{ $t('common.target_period') }}
                        </span>
                      </label>
                      <div class="input-group">
                        <el-date-picker
                          class="date-input"
                          popper-class="bottom"
                          type="date"
                          v-model="startDate"
                          :clearable="false"
                          :disabled-date="isDisabledStartDate"
                          :placeholder="$t('common.selection_required')"
                          format="YYYY/MM/DD"
                          value-format="YYYY-MM-DD"
                          :class="
                            (!checkEmpty(errors.date_from) || !checkEmpty(errors.date)) &&
                            'has-validation-error-el-datepicker'
                          "
                        />
                        <span class="input-group-addon prn">{{ $t('common.from') }}</span>
                      </div>
                      <p role="alert" class="error-feedback" v-text="errors.date_from" />
                      <p role="alert" class="error-feedback" v-text="errors.date" />
                    </div>
                  </div>
                  <div
                    class="col-md-6 col-all-closer col-all-closer--rightend col-sm-closer--leftend col-xs-closer--leftend"
                  >
                    <div class="form-group">
                      <label><span class="label-text">&nbsp;</span></label>
                      <div class="input-group">
                        <el-date-picker
                          class="date-input"
                          popper-class="bottom"
                          type="date"
                          v-model="endDate"
                          :clearable="false"
                          :disabled-date="isDisabledEndDate"
                          :placeholder="$t('common.selection_required')"
                          format="YYYY/MM/DD"
                          :class="
                            (!checkEmpty(errors.date_to) || !checkEmpty(errors.date)) &&
                            'has-validation-error-el-datepicker'
                          "
                          value-format="YYYY-MM-DD"
                        />
                        <span class="input-group-addon prn">{{ $t('common.to') }}</span>
                      </div>
                      <p role="alert" class="error-feedback" v-text="errors.date_to" />
                    </div>
                  </div>
                </div>

                <div class="row ptm show--edit hide--view">
                  <div class="col-sm-4 col-sm-offset-4">
                    <button class="btn btn-sm btn-block btn-inverse-primary js-btn--search" @click="searchTargetPerson">
                      {{ $t('create_security.search_for_the_target_person') }}
                    </button>
                  </div>
                </div>
                <hr style="margin-bottom: 8px" />
                <div class="result hide--new" v-if="isSearchSuccess">
                  <div style="display: flex; align-items: center; justify-content: space-between">
                    <h5 class="">{{ $t('security.target_person') }}</h5>
                  </div>
                  <div>
                    <DataTableTargetPerson
                      :list-target-person="listTargetPerson"
                      :listCountryTarget="listCountryTarget"
                      @on-change-member-selected="selectMember"
                      @on-change-list-target-person-selected="onChangeListTargetPersonSelected"
                    />
                  </div>
                  <div class="row mtm ptm" v-if="listTargetPerson?.length && listTargetPerson.length > 0">
                    <div class="col-sm-4 col-sm-offset-4">
                      <button
                        class="btn btn-sm btn-block btn-primary js-btn--save js-btn--newlist open-alert"
                        data-tgt="al--saved"
                        @click="onCreateSafetyList"
                      >
                        {{ $t('create_security.create_safety_list') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="isExpandMap">
            <div class="trip-info-col bg-gray col-expandable" style="width: calc(100% - 55px); z-index: 1">
              <button class="btn btn-sm btn-flat-default btn-col-expand expanded" @click="isExpandMap = false">
                <i class="icon-abui-contract" />
              </button>
              <div
                class="security--map hide--new"
                style="
                  background-image: url(/assets/images/dummy/dummny-map--secutiry2.png);
                  background-position: 1200px -40px;
                  background-size: 1923px;
                  height: 100%;
                "
              >
                <div class="section noborder" style="height: 800px; padding: 0px !important">
                  <GMapMap
                    v-if="!checkEmpty(listMarker)"
                    :center="!checkEmpty(listMarker[0]?.position) ? listMarker[0]?.position : center"
                    :options="options"
                    :zoom="3"
                    :key="componentKey"
                    map-type-id="terrain"
                    style="height: 100vh"
                  >
                    <div v-show="listMarker.length">
                      <gmap-custom-marker v-for="(marker, index) in listMarker" :key="index"  :marker="!checkEmpty(marker.position) ? marker.position : center">
                        <div v-if="marker.answer_type == 2" class="security--map focused">
                          <div class="security--map--people focus done" :class="!checkEmpty(marker.position) && 'gps'">
                            <span class="people--gps" v-if="marker.isGPS">
                              <i class="icon-abui-gps" />
                            </span>
                            <div
                              class="baloon dropshadow--lv2 answer--ok"
                              :style="checkEmpty(marker.answer_message) && 'background-color: #d0d6da'"
                            >
                              <span class="baloon--inner">
                                {{
                                  !checkEmpty(marker.answer_message) ? marker.answer_message : $t('security.no_comment')
                                }}
                              </span>
                            </div>
                            <div class="name--wrap dropshadow--lv1">
                              <span class="name">
                                {{ marker.member_name ? marker.member_name : marker.full_name ? marker.full_name : '' }}
                                <span class="phone--people">{{ marker.phone }}</span>
                              </span>
                              <button class="btn btn-sm btn-flat-default" @click="showEmergencyContact(marker)">
                                <i class="icon-abui-bullhorn" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="marker.answer_type == 3" class="security--map focused">
                          <div
                            class="security--map--people focus done ng"
                            :class="!checkEmpty(marker.position) && 'gps'"
                          >
                            <span class="people--gps" v-if="marker.isGPS">
                              <i class="icon-abui-gps" />
                            </span>
                            <div
                              class="baloon dropshadow--lv2 answer--ok"
                              :style="checkEmpty(marker.answer_message) && 'background-color: #d0d6da'"
                            >
                              <span class="baloon--inner">
                                {{
                                  !checkEmpty(marker.answer_message) ? marker.answer_message : $t('security.no_comment')
                                }}
                              </span>
                            </div>
                            <div class="name--wrap dropshadow--lv1">
                              <span class="name">
                                {{ marker.member_name ? marker.member_name : marker.full_name ? marker.full_name : '' }}
                                <span class="phone--people">{{ marker.phone }}</span>
                              </span>
                              <button class="btn btn-sm btn-flat-default" @click="showEmergencyContact(marker)">
                                <i class="icon-abui-bullhorn" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div v-else class="security--map focused">
                          <div class="security--map--people focus" :class="!checkEmpty(marker.position) && 'gps'">
                            <span class="people--gps" v-if="marker.isGPS">
                              <i class="icon-abui-gps" />
                            </span>
                            <div class="name--wrap dropshadow--lv1">
                              <span class="name">
                                {{ marker.member_name ? marker.member_name : marker.full_name ? marker.full_name : '' }}
                                <span class="phone--people">{{ marker.phone }}</span>
                              </span>
                              <button class="btn btn-sm btn-flat-default" @click="showEmergencyContact(marker)">
                                <i class="icon-abui-bullhorn" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </gmap-custom-marker>
                    </div>
                  </GMapMap>
                  <GMapMap
                    v-else
                    :center="!checkEmpty(targetCountry) ? targetCountry : center"
                    :options="options"
                    :zoom="3"
                    map-type-id="terrain"
                    style="height: 100vh"
                  >
                    <div v-show="listTargetPersonWithMarker.length">
                      <gmap-custom-marker v-for="(marker, index) in listTargetPersonWithMarker" :key="index" :marker="!checkEmpty(marker.position) ? marker.position : center">
                        <div class="security--map focused">
                          <div class="security--map--people focus" :class="!checkEmpty(marker.position) && 'gps'">
                            <div class="name--wrap dropshadow--lv1">
                              <span class="name">
                                {{ marker.member_name ? marker.member_name : marker.full_name ? marker.full_name : '' }}
                                <span class="phone--people">{{ !checkEmpty(marker.phone) ? marker.phone : '' }}</span>
                              </span>
                              <button class="btn btn-sm btn-flat-default" @click="showEmergencyContact(marker)">
                                <i class="icon-abui-bullhorn" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </gmap-custom-marker>
                    </div>
                  </GMapMap>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>
<script>
import MultiOptionSelect from '@/components/common/MultiOptionSelect.vue';
import moment from 'moment';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import DataTableTargetPerson from '@/components/security/DataTableTargetPerson';

export default {
  name: 'Zone',
  components: { MultiOptionSelect, 'gmap-custom-marker': GmapCustomMarker, DataTableTargetPerson },
  data() {
    return {
      isExpandMap: false,
      isShowModalEmergencyContact: false,
      selectedEmergencyContacts: [],
      selectedMember: {},
      errors: {
        target_country: '',
        date: '',
        date_from: '',
        date_to: '',
        airline: '',
        flight_number: '',
      },
      typeFilter: this.$t('create_security.from_the_country'),
      listCountry: [],
      listAirline: [],
      startDate: '',
      endDate: '',
      flightNumber: '',
      listCountrySelected: [],
      listAirlineSelected: [],
      isSearchSuccess: false,
      listTargetPerson: [],
      listTargetPersonWithMarker: [],
      listTargetPersonSelected: [],
      defaultChecked: true,
      listNameCountrySelected: '',
      listNameAirlineSelected: '',
      center: {
        lat: 35.672855,
        lng: 139.817413,
      },
      listData: [],
      options: {
        disableDefaultUI: true,
        minZoom: 3,
        maxZoom: 12,
      },
      isSubmit: false,
      markerSelected: [],
      listCountryTarget: [],
      collectionCountry: [],
      targetCountry: {},
      ctrTarget: {},
      componentKey: 1,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (
      (!this.checkEmpty(this.startDate) ||
        !this.checkEmpty(this.endDate) ||
        !this.checkEmpty(this.listCountrySelected) ||
        !this.checkEmpty(this.listAirlineSelected) ||
        !this.checkEmpty(this.listTargetPersonSelected) ||
        !this.checkEmpty(this.flightNumber)) &&
      !this.isSubmit
    ) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  created() {
    this.startLoading();
    Promise.all([this.getListCountry(), this.getListAirLines()]).then(() => {
      this.endLoading();
    });
  },
  computed: {
    isShowFilterCountry() {
      return this.typeFilter == this.$t('create_security.from_the_country');
    },
    companyAddress() {
      return this.$store.state.auth?.user?.company?.address || '';
    },
    listMarker() {
      let arrMarker = [];
      this.listTargetPersonSelected.forEach((selectedMember) => {
        if (!this.checkEmpty(selectedMember)) {
          if (!this.checkEmpty(selectedMember.gps_confirm_safety_info)) {
            let marker = {
              ...selectedMember,
              position: {
                lat: Number(selectedMember.gps_confirm_safety_info.coordinate.lat),
                lng: Number(selectedMember.gps_confirm_safety_info.coordinate.lng),
              },
              isGPS: true,
            };
            arrMarker.push(marker);
          }

          if (!this.checkEmpty(selectedMember.gps_map_data)) {
            let marker = {
              ...selectedMember,
              position: {
                lat: Number(selectedMember.gps_map_data.coordinate.lat),
                lng: Number(selectedMember.gps_map_data.coordinate.lng),
              },
              isGPS: true,
            };
            arrMarker.push(marker);
          }

          if (!this.checkEmpty(selectedMember.over_seas_data)) {
            let marker = {
              ...selectedMember,
              position: {
                lat: Number(selectedMember.over_seas_data.coordinate.lat),
                lng: Number(selectedMember.over_seas_data.coordinate.lng),
              },
            };
            arrMarker.push(marker);
          }

          if (!this.checkEmpty(selectedMember.schedule_data)) {
            selectedMember.schedule_data.forEach(schedule => {
              if (!this.checkEmpty(schedule.arrival_coordinate)) {
                let marker = {
                  ...selectedMember,
                  position: {
                    lat: Number(schedule.arrival_coordinate.lat),
                    lng: Number(schedule.arrival_coordinate.lng),
                  },
                };
                arrMarker.push(marker);
              }
              if (!this.checkEmpty(schedule.departure_coordinate)) {
                let marker = {
                  ...this.selectedMember,
                  position: {
                    lat: Number(schedule.departure_coordinate.lat),
                    lng: Number(schedule.departure_coordinate.lng),
                  },
                };
                arrMarker.push(marker);
              }
            });
          }
        }
      });
      return arrMarker;
    },
    listMarker2() {
      let arrMarker = [];
      if (!this.checkEmpty(this.selectedMember)) {
        if (!this.checkEmpty(this.selectedMember.gps_confirm_safety_info)) {
          let marker = {
            ...this.selectedMember,
            position: {
              lat: Number(this.selectedMember.gps_confirm_safety_info.coordinate.lat),
              lng: Number(this.selectedMember.gps_confirm_safety_info.coordinate.lng),
            },
            isGPS: true,
          };
          arrMarker.push(marker);
        }

        if (!this.checkEmpty(this.selectedMember.gps_map_data)) {
          let marker = {
            ...this.selectedMember,
            position: {
              lat: Number(this.selectedMember.gps_map_data.coordinate.lat),
              lng: Number(this.selectedMember.gps_map_data.coordinate.lng),
            },
            isGPS: true,
          };
          arrMarker.push(marker);
        }

        if (!this.checkEmpty(this.selectedMember.schedule_data)) {
          this.selectedMember.schedule_data.forEach(schedule => {
            if (!this.checkEmpty(schedule.arrival_coordinate)) {
              let marker = {
                ...this.selectedMember,
                position: {
                  lat: Number(schedule.arrival_coordinate.lat),
                  lng: Number(schedule.arrival_coordinate.lng),
                },
              };
              arrMarker.push(marker);
            }
            if (!this.checkEmpty(schedule.departure_coordinate)) {
              let marker = {
                ...this.selectedMember,
                position: {
                  lat: Number(schedule.departure_coordinate.lat),
                  lng: Number(schedule.departure_coordinate.lng),
                },
              };
              arrMarker.push(marker);
            }
          });
        }
      }

      return arrMarker;
    },
  },
  watch: {
    typeFilter() {
      this.errors = {};
    },
    selectedMember() {
      this.componentKey += 1;
      let listData = JSON.parse(JSON.stringify(this.listTargetPerson));
      listData.forEach((member, index) => {
        if (member.member_id == this.selectedMember.member_id) {
          listData.splice(index, 1);
        }
      });

      this.listData = listData;
    },
    companyAddress() {
      this.getDefaultCenterMap();
    },
    startDate(val) {
      if (this.checkEmpty(this.endDate)) {
        this.endDate = val;
      }
    },
    collectionCountry() {
      let a = [],
        b = [],
        prev;
      let listCtr = this.cloneObject(this.collectionCountry);

      listCtr.sort();
      for (var i = 0; i < listCtr.length; i++) {
        if (listCtr[i] !== prev) {
          a.push(listCtr[i]);
          b.push(1);
        } else {
          b[b.length - 1]++;
        }
        prev = listCtr[i];
      }

      let countryIndex = 0;
      let maxId = 0;
      if (!this.checkEmpty(b)) {
        b.forEach((i, index) => {
          if (i >= maxId) {
            maxId = i;
            countryIndex = index;
          }
        });
      }

      this.listTargetPerson.forEach(member => {
        if (!this.checkEmpty(member.countries)) {
          member.countries.forEach(ctr => {
            if (ctr.id == a[countryIndex]) this.ctrTarget = ctr;
          });
        }
      });
    },
    ctrTarget(val) {
      if (!this.checkEmpty(val)) {
        this.$services.common.searchTimeZones(
          { address: this.labelLocale(val) + '' },
          res => {
            if (res.lat & res.lng) {
              this.targetCountry = {
                lat: res.lat,
                lng: res.lng,
              };
            }
          },
          () => {},
        );
      }
    },
  },
  methods: {
    getDefaultCenterMap() {
      if (this.companyAddress) {
        this.$services.common.searchTimeZones(
          { address: this.companyAddress },
          res => {
            if (res) this.setCenterMap(res);
          },
          () => {},
        );
      }
    },
    setCenterMap(data) {
      if (data.lat & data.lng) {
        this.center = {
          lat: +data.lat + Math.random() * 0.00001,
          lng: +data.lng,
        };
      }
    },
    async getListCountry() {
      await this.$services.security.getListCountry(
        res => {
          this.listCountry = this.sortCountryByName(res.list);
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    async getListAirLines() {
      await this.$services.security.getListAirLines(
        res => {
          this.listAirline = res.list;
        },
        err => {
          this.notifyError(this.$t(err.error));
        },
      );
    },
    updateListCountrySelected(arrCountry) {
      let result = '';
      this.listCountrySelected = arrCountry;
      for (let i = 0; i < arrCountry.length; i++) {
        for (let j = 0; j < this.listCountry.length; j++) {
          if (this.listCountry[j].id == arrCountry[i]) {
            result += this.labelLocale(this.listCountry[j]);
            if (i < arrCountry.length - 1) {
              result += this.$t('common.comma');
            }
          }
        }
      }
      this.listNameCountrySelected = result;
      if (!this.checkEmpty(this.listCountrySelected)) {
        this.listCountrySelected.forEach(country => {
          let ctr = this.listCountry.find(item => item.id == country);
          if (!this.checkEmpty(ctr)) {
            this.listCountryTarget.push(ctr);
          }
        });
      }
    },
    updateListAirlineSelected(arrAirLine) {
      this.listAirlineSelected = arrAirLine;
    },
    searchTargetPerson() {
      let flgValidate = true;
      if (this.checkEmpty(this.startDate)) {
        flgValidate = false;
        this.errors.date_from = this.$t('E-CM-004');
      } else {
        this.errors.date_from = '';
      }

      if (this.checkEmpty(this.endDate)) {
        flgValidate = false;
        this.errors.date_to = this.$t('E-CM-004');
      } else {
        this.errors.date_to = '';
      }

      if (moment(new Date(this.startDate)).format('YYYY-MM-DD') > moment(this.endDate).format('YYYY-MM-DD')) {
        flgValidate = false;
        this.errors.date = this.$t('E-CU-008', { to: this.$t('common.to'), from: this.$t('common.from') });
      } else {
        this.errors.date = '';
      }

      if (this.isShowFilterCountry) {
        if (this.listCountrySelected.length == 0) {
          flgValidate = false;
          this.errors.target_country = this.$t('E-CU-024');
        } else {
          this.errors.target_country = '';
        }
      } else {
        if (this.listAirlineSelected.length == 0) {
          flgValidate = false;
          this.errors.airline = this.$t('E-CU-024');
        } else {
          this.errors.airline = '';
        }
      }

      if (!flgValidate) return;
      this.isSearchSuccess = true;
      this.submitSearch();
    },
    submitSearch() {
      let query = '';
      let params = {
        flight_number: this.flightNumber,
        departure_time: this.startDate,
        arrival_time: this.endDate,
      };
      // add param query with radio option
      if (this.isShowFilterCountry) {
        this.listCountrySelected.map(country_id => {
          query += '&country_ids[]=' + country_id;
        });
      } else {
        this.listAirlineSelected.map(airline_id => {
          query += '&airline_ids[]=' + airline_id;
        });
      }
      query = query.substr(1);
      this.startLoading();
      this.$services.security.getListTargetPerson(
        params,
        query,
        res => {
          this.endLoading();
          this.listTargetPerson = res.list;
          this.addMarkerMember();
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    isDisabledStartDate(time) {
      const { endDate } = this;
      if (!endDate) return false;

      return moment(new Date(time)).format('YYYY-MM-DD') > moment(endDate).format('YYYY-MM-DD');
    },
    isDisabledEndDate(time) {
      const { startDate } = this;
      if (!startDate) return false;

      return moment(new Date(time)).format('YYYY-MM-DD') < moment(startDate).format('YYYY-MM-DD');
    },
    addMarkerMember() {
      this.selectedMember = {};
      this.listTargetPersonWithMarker = [];
      this.listTargetPersonSelected = [];
      if (!this.checkEmpty(this.listTargetPerson)) {
        let listData = this.cloneObject(this.listTargetPerson);
        listData.forEach(person => {
          if (!this.checkEmpty(person.gps_map_data)) {
            let item = {
              ...person,
              position: {
                lat: Number(person.gps_map_data.coordinate.lat),
                lng: Number(person.gps_map_data.coordinate.lng),
              },
            };
            this.listTargetPersonWithMarker.push(item);
          }

          if (!this.checkEmpty(person.over_seas_data)) {
            let item = {
              ...person,
              position: {
                lat: Number(person.over_seas_data.coordinate.lat),
                lng: Number(person.over_seas_data.coordinate.lng),
              },
            };
            this.listTargetPersonWithMarker.push(item);
          }

          if (!this.checkEmpty(person.schedule_data)) {
            person.schedule_data.forEach(schedule => {
              if (!this.checkEmpty(schedule.arrival_coordinate)) {
                let item = {
                  ...person,
                  position: {
                    lat: Number(schedule.arrival_coordinate.lat),
                    lng: Number(schedule.arrival_coordinate.lng),
                  },
                };
                this.listTargetPersonWithMarker.push(item);
              }
              if (!this.checkEmpty(schedule.departure_coordinate)) {
                let item = {
                  ...person,
                  position: {
                    lat: Number(schedule.departure_coordinate.lat),
                    lng: Number(schedule.departure_coordinate.lng),
                  },
                };
                this.listTargetPersonWithMarker.push(item);
              }
            });
          }
        });
      }
      this.collectionCountry = [];
      this.listTargetPerson.forEach(member => {
        if (!this.checkEmpty(member.countries)) {
          member.countries.forEach(ctr => {
            this.collectionCountry.push(ctr.id);
          });
        }
      });
    },
    selectMember(item) {
      this.selectedMember = item;
      this.selectedEmergencyContacts = item.emergency_contacts ? item.emergency_contacts : [];
    },
    onChangeListTargetPersonSelected(listData) {
      let arr = [];
      listData.forEach(personSelected => {
        this.listTargetPerson.forEach(person => {
          if (personSelected == person.trip_id) {
            arr.push(person);
          }
        });
      });
      this.listTargetPersonSelected = arr;
    },
    onCreateSafetyList() {
      this.isSubmit = true;
      if (!this.checkEmpty(this.listTargetPersonSelected)) {
        this.startLoading();
        let params = {
          country_ids: [],
          start_date: this.startDate != '' ? this.showDateTime(this.startDate, 'YYYY/MM/DD') : '',
          end_date: this.endDate != '' ? this.showDateTime(this.endDate, 'YYYY/MM/DD') : '',
          target_users: [],
        };
        this.listTargetPersonSelected.forEach(person => {
          let targetUser = {
            trip_id: person.trip_id,
            user_id: person.member_id,
          };
          params.target_users.push(targetUser);
        });
        if (this.typeFilter == this.$t('create_security.from_the_country')) {
          for (let i = 0; i < this.listCountrySelected.length; i++) {
            params.country_ids.push(this.listCountrySelected[i]);
          }
        } else {
          this.listTargetPersonSelected.forEach(person => {
            person.countries.forEach(country => {
              params.country_ids.push(country.id);
            });
          });
        }
        this.$services.security.createSecurity(
          params,
          res => {
            this.$router.push({
              name: this.PAGES.CRISIS_MANAGEMENT_DETAIL,
              params: {
                id: res.id,
              },
            });
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
        );
      } else {
        this.notifyError(this.$t('E-CU-017'));
      }
    },
    sendBroadcastToSingleUser() {
      this.startLoading();
      this.$services.security.sendBroadcastToSingleUser(
        this.selectedMember.member_id,
        () => {
          this.endLoading();
          this.notifySuccess(this.$t('E-CU-020'));
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
    showEmergencyContact(item) {
      this.selectedEmergencyContacts = item.emergency_contacts ? item.emergency_contacts : [];
      this.isShowModalEmergencyContact = true;
    },
  },
};
</script>
<style scoped>
.navbar {
  position: fixed;
  width: calc(100% - 55px);
  z-index: 100;
}
.body-content {
  margin-top: 64px;
}
.plan-stage {
  width: calc(100% - 480px);
  margin-bottom: 62px;
}
.dropdown-menu.menu-parent {
  max-height: 533.812px;
  min-height: 0px;
  visibility: visible;
  opacity: 1;
  margin: 4px 0px !important;
}
.dropdown-menu {
  margin: 4px 0px !important;
  opacity: 1;
  visibility: visible;
}
.caret.active {
  border-top-color: #3650a5;
  border-bottom-color: #3650a5;
}
.caret {
  margin-left: 2px;
}
.checkbox-type-div {
  margin-top: 1rem !important;
}
#chkbox_type_2 {
  margin-left: 2.5rem !important;
}
.checkbox-radio-div {
  margin: 12px 22px 12px 0px !important;
}
.text_type {
  padding-left: 3rem !important;
}
.text_type_radio {
  padding-left: 3.2rem !important;
}
#checkbox_radio_type_2 {
  margin-left: 2.5rem !important;
}
.checkbox-expense {
  margin-left: 2.5rem !important;
}
.checkbox-abroad {
  margin-left: 2.5rem !important;
}
.type-approver {
  margin-left: 2.5rem !important;
}
.security--map--people {
  top: -70px;
  left: -30px;
}
</style>
