<template>
  <div v-if="!checkEmpty(allCosts) || !tripIdParams" class="ringi-approve-wrap">
    <div
        v-if="!checkEmpty(requestHistories) && !isApprovedExpensesModal"
        class="hide--status10 hide--ringi"
        @click="showCostHistorySelect = !showCostHistorySelect"
    >
      <div class="form-group">
        <div
            class="btn-group bootstrap-select select-block select-sm"
            :class="showCostHistorySelect && 'open'"
            v-click-outside="
            () => {
              showCostHistorySelect = false;
            }
          "
        >
          <button
              type="button"
              class="btn dropdown-toggle btn-input"
              data-toggle="dropdown"
              role="button"
              aria-expanded="false"
          >
            <span class="filter-option pull-left">
              {{ `(${textToRequestType(selectedRequestDate?.type) + '-' + selectedRequestDate.status_name}) ` + selectedRequestDate?.date }}
            </span>
            &nbsp;
            <span class="bs-caret">
              <span class="caret"></span>
            </span>
          </button>
          <div
              class="dropdown-menu"
              :class="showCostHistorySelect && 'open'"
              role="combobox"
              style="max-height: 747.003px; overflow: hidden; min-height: 0px"
          >
            <ul
                class="dropdown-menu inner"
                role="listbox"
                aria-expanded="false"
                style="max-height: 300px; overflow-y: auto; min-height: 0px"
            >
              <li
                  data-original-index="0"
                  v-for="(item, index) in requestHistories"
                  :key="index"
                  :class="item.id == selectedRequestDate.id && 'selected'"
                  @click="selectDate(item)"
              >
                <a tabindex="0" class="" data-tokens="null" role="option" aria-disabled="false" aria-selected="true">
                  <span class="text">{{ `(${textToRequestType(item.type) + '-' + item.status_name}) ` + item.date }} </span>
                  <span class="glyphicon"></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isApprovedExpensesModal">
      <div class="apply-ringi-purpose" style="">
        <div class="form-group viewonly">
          <label>
            <span class="label-text">{{ $t('trip_item.main_content.purpose') }}</span>
          </label>
          <pre class="viewonly-text input-sm" v-html="allCosts.request?.purpose" />
        </div>
      </div>

      <div class="row" v-if="!checkEmpty(allCosts.request?.comment)">
        <div class="col-sm-12">
          <div class="form-group viewonly break-word-custom">
            <label>
              <span class="label-text">{{ $t('business_report.comment_title') }}</span>
            </label>
            <pre class="viewonly-text input-sm label-text" v-html="allCosts.request?.comment" />
          </div>
        </div>
      </div>

      <div class="apply-expense--total--wrap" v-if="tripIdParams">
        <div class="apply-expense--total-sum">
          <span class="apply-expense--total-sum--appendix">{{ $t('business_report.amount') }}</span>
          {{ splitThousandYen(calTotalCost) }}
        </div>
        <a
            v-if="flg_old_val"
            class="js-btn--reffer-ringi has-pointer"
            style="display: block"
        >
          <div class="apply-expense--total-applied">
            <span>{{ $t('business_report.approval') }}</span>
            <span class="value">{{ splitThousandYen(calTotalCostOld) }}</span>
          </div>
          <div class="apply-expense--total-dif">
            <span></span>
            <span class="value text-warning"> （ {{ calTotalCostDiff }} ）</span>
          </div>
        </a>
      </div>
    </div>

    <hr v-if="tripIdParams" class="mbl" />

    <div class="mbl">
      <!-- Daily allowance fee -->
      <div v-if="tripIdParams && isShowDailyAllowance" class="apply-expense--section-head">
        <h6>{{ $t('trip_item.main_content.daily_allowance') }}</h6>
        <span
            v-if="flg_old_val"
            class="apply-expense--price__before hide--status10 hide--status20"
        >
          {{ $t('business_report.last_time') }}
          {{ splitThousandYen(calDailyAllowanceCostOld) }}
        </span>
        <div class="apply-expense--section-sum" :class="flg_old_val && splitThousandYen(calDailyAllowanceCostOld) !== splitThousandYen(calDailyAllowanceCost) && 'text-warning'">
          {{ splitThousandYen(calDailyAllowanceCost) }}
        </div>
      </div>

      <div class="apply-expense--wrap" v-if="!checkEmpty(groupedDailyAllowances) && tripIdParams && isShowDailyAllowance">
        <div v-for="(item, index) in groupedDailyAllowances" :key="index" class="apply-expense--item fixed">
          <span class="apply-expense--icon"><i class="icon-abui-check"></i></span>
          <span class="apply-expense--title">
            {{ item?.daily_allowance_type != null ? item.daily_allowance_type : '' }}
          </span>
          <span class="apply-expense--count" v-html="item.count"></span>
          <span class="apply-expense--price" v-html="splitThousandYen(item.amount)"></span>
        </div>
      </div>

      <!-- Insurance fee -->
      <div class="apply-expense--section-head" v-if="tripIdParams">
        <h6>{{ $t('trip_item.main_content.insurance') }}</h6>
        <span
            v-if="flg_old_val"
            class="apply-expense--price__before hide--status10 hide--status20"
        >
          {{ $t('business_report.last_time') }}
          {{ splitThousandYen(calInsuranceCostOld) }}
        </span>
        <div class="apply-expense--section-sum" :class="flg_old_val && splitThousandYen(calInsuranceCostOld) !== splitThousandYen(calInsuranceCost) && 'text-warning'">
          {{ splitThousandYen(calInsuranceCost) }}</div>
      </div>

      <div class="apply-expense--wrap" v-if="tripIdParams && !checkEmpty(allCosts?.insurance) && allCosts.insurance[0]?.amount > 0">
        <div v-for="(item, index) in allCosts.insurance" :key="index" class="apply-expense--item fixed">
          <span class="apply-expense--icon"><i class="icon-abui-check"></i></span>
          <span class="apply-expense--title">{{ item.title }}</span>
          <span class="apply-expense--date">
            {{ showDateTime(item.payment_date, 'YYYY/MM/DD') }}
          </span>
          <span class="apply-expense--payto">{{ item.payable_to }}</span>
          <span class="apply-expense--paymenttype">
            {{ isNumeric(item.payment_method) ? textToPaymentMethod(item.payment_method) : item.payment_method }}
          </span>
          <span class="apply-expense--price" v-html="splitThousandYen(item.amount)"></span>
        </div>
      </div>

      <!-- Company Cost Items -->
      <div v-if="tripIdParams && !checkEmpty(allCosts?.company_cost_items)">
        <div
          v-for="(costItem, costIndex) in allCosts.company_cost_items"
          :key="costIndex"
          class="mbl expence--approve--wrap"
        >
          <div class="apply-expense--section-head">
            <h6>{{ costItem?.title || '' }}</h6>
            <span
                v-if="flg_old_val"
                class="apply-expense--price__before hide--status10 hide--status20"
            >
              {{ $t('business_report.last_time') }}
              {{ splitThousandYen(totalCompanyCostItemOld(costItem)) || '¥0' }}
            </span>
            <div class="apply-expense--section-sum" :class="flg_old_val && splitThousandYen(totalCompanyCostItemOld(costItem)) !== splitThousandYen(totalCompanyCostItem[costIndex]) && 'text-warning'">
              {{ splitThousandYen(totalCompanyCostItem[costIndex]) || '¥0' }}
            </div>
          </div>
          <div class="apply-expense--wrap" v-if="!checkEmpty(costItem?.cost_items)">
            <div
                v-for="(item, index) in costItem.cost_items"
                :key="index"
                :class="isEmbedFromRequest && 'cursor-pointer'"
                @click="chooseCostRow(item)"
            >
              <div
                  class="apply-expense--item fixed"
                  :class="selectedCost.name === `${item.title}-${item.cost_id}` && 'active'"
              >
                <span class="apply-expense--icon"><i class="icon-abui-check"></i></span>
                <span class="apply-expense--title" v-html="item.title"></span>
                <span
                    class="apply-expense--date"
                    v-html="item.payment_date && showDateTime(item.payment_date, 'YYYY/MM/DD')"
                ></span>
                <span class="apply-expense--payto" v-html="item.payable_to"></span>
                <span class="apply-expense--paymenttype" v-html="textToPaymentMethod(item.payment_method)"></span>
                <span class="apply-expense--price">{{splitThousandYen(item.amount)}}
                <p class="box-eki" v-if="item.flg_change_eki == 1"><span class="change-eki-cost">{{ $t('expense.with_modification') }}</span></p></span>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <!-- Cost Estimate Items -->
      <div v-if="tripIdParams && !checkEmpty(allCosts.cost_estimate)">
        <div v-for="(costItem, costIndex) in allCosts.cost_estimate" :key="costIndex">
          <div class="apply-expense--section-head">
            <h6>{{ groupScheduleTypeLabel(costItem.schedule_group_type) }}</h6>
            <span
                v-if="flg_old_val"
                class="apply-expense--price__before hide--status10 hide--status20"
            >
              {{ $t('business_report.last_time') }}
              {{ splitThousandYen(totalCompanyCostEstimateOld(costItem)) || '¥0' }}
            </span>
            <div class="apply-expense--section-sum"  :class="flg_old_val && splitThousandYen(totalCompanyCostEstimateOld(costItem)) !== splitThousandYen(totalEstimateCost[costIndex]) && 'text-warning'">
              {{ splitThousandYen(totalEstimateCost[costIndex]) || '¥0' }}
            </div>
          </div>
          <div class="apply-expense--wrap" v-if="!checkEmpty(costItem?.cost_items)">
            <div v-for="(item, index) in costItem.cost_items" :key="index" class="apply-expense--item fixed">
              <span class="apply-expense--icon"><i class="icon-abui-check"></i></span>
              <span class="apply-expense--title">
                {{ item.title + '(' + $t('business_report.estimate_cost') + ')' }}</span
              >
              <span
                  class="apply-expense--date"
                  v-html="item.payment_date && showDateTime(item.payment_date, 'YYYY/MM/DD')"
              ></span>
              <span class="apply-expense--payto" v-html="item.payable_to"></span>
              <span class="apply-expense--paymenttype" v-html="textToPaymentMethod(item.payment_method)"></span>
              <span class="apply-expense--price" v-html="splitThousandYen(item.amount)"></span>
            </div>
          </div>
        </div>
      </div>

      <!-- WifiRouterRental -->
      <div class="apply-expense--section-head" v-if="tripIdParams">
        <h6>{{ $t('settings.temporary_expenses') }}</h6>
        <span
            v-if="flg_old_val"
            class="apply-expense--price__before hide--status10 hide--status20"
        >
          {{ $t('business_report.last_time') }}
          {{ splitThousandYen(calWifiRouterRentalCostOld) }}
        </span>
        <div class="apply-expense--section-sum" :class="flg_old_val && splitThousandYen(calWifiRouterRentalCostOld) !== splitThousandYen(calWifiRouterRentalCost) && 'text-warning'">
          {{ splitThousandYen(calWifiRouterRentalCost) }}</div>
      </div>

      <div class="apply-expense--wrap" v-if="!checkEmpty(allCosts?.wifi_router_rental) && allCosts.wifi_router_rental[0]?.amount > 0 && tripIdParams">
        <div v-for="(item, index) in allCosts.wifi_router_rental" :key="index" class="apply-expense--item fixed">
          <span class="apply-expense--icon"><i class="icon-abui-check"></i></span>
          <span class="btn btn-used-in-request" style="background-color: #dcb823; color: #fff">
             {{ $t('settings.temporary_expenses_label')}}
          </span>
          <span class="apply-expense--title">{{ item.title }}</span>
          <span class="apply-expense--date">
            {{ showDateTime(item.payment_date, 'YYYY/MM/DD') }}
          </span>
          <span class="apply-expense--payto">{{ item.payable_to }}</span>
          <span class="apply-expense--paymenttype">
            {{ isNumeric(item.payment_method) ? textToPaymentMethod(item.payment_method) : item.payment_method }}
          </span>
          <span class="apply-expense--price" v-html="splitThousandYen(item.amount)"></span>
        </div>
      </div>
      <hr  v-if="tripIdParams"/>

      <!-- Cost bearing organizations -->
      <div v-if="!checkEmpty(allCosts.cost_bearing_organizations)">
        <div class="apply-expense--section-head">
          <h6>{{ $t('business_report.cost_bearing') }}</h6>
          <!-- <div class="apply-expense--section-sum">{{ splitThousandYen(costItem.amount) }}</div> -->
        </div>
        <div class="apply-expense--wrap">
          <div
              v-for="(item, index) in allCosts?.cost_bearing_organizations"
              :key="index"
              class="apply-expense--item fixed"
          >
            <span class="apply-expense--icon"><i class="icon-abui-check"></i></span>
            <span class="apply-expense--title" v-html="item.organization_name"></span>

            <span class="apply-expense--paymenttype">{{ item.price_percentage }}%</span>
            <span class="apply-expense--price" v-html="splitThousandYen(item.amount)"></span>
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>
  <ModalExpenseCost
      ref="ModalExpenseCost"
      :view-only="true"
      :dialogVisible="showExpenseCost"
      :expenseCostDetail="expenseCostDetail"
      :listFile="listFile"
      :list-schedule="listTripSchedule"
      @cancel="reloadData"
  />
</template>

<script>
import { payment_reimbursement, PAYMENT_CORPORATE_CARD, PAYMENT_INVOICE } from '@/constants';
import ModalExpenseCost from '@/components/common/ModalExpenseCost';
import vClickOutside from 'click-outside-vue3';
import mixins from '@/helpers/mixins';

export default {
  name: 'CostTab',
  emits: ['emitTotalCost'],
  mixins: [mixins],
  components: { ModalExpenseCost },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    tripIdParams: {
      type: Number,
      default: 0,
    },
    requestHistories: {
      type: Array,
      default: () => [],
    },
    dateValue: {
      type: String,
      default: '',
    },
    requestIdParams: {
      type: Number,
      default: 0,
    },
    isApprovedExpensesModal: {
      type: Boolean,
      default: false,
    },
    isEmbedFromRequest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allCosts: {},
      flg_old_val: true,
      countLoading: 0,
      allCostsOld: {},
      showCostHistorySelect: false,
      showExpenseCost: false,
      expenseCostDetail: {},
      selectedRequestDate: {},
      lastRequestId: null,
      listFile: [],
      listTripSchedule: [],
      selectedCost: {
        name: '',
        file_url: '',
        cost_title: '',
      },
    };
  },
  created() {
    if (this.isRequestPage) {
      this.startLoading();
    }
    if (!this.checkEmpty(this.requestHistories) && this.checkEmpty(this.selectedRequestDate)) {
      let selectedRequestDateCheck = this.requestHistories.filter(item => {
        return item.id == this.requestId;
      });
      if (selectedRequestDateCheck.length == 0) {
        this.selectedRequestDate = {
          id: this.requestHistories[this.requestHistories.length - 1].id,
          date: this.requestHistories[this.requestHistories.length - 1].date,
          type: this.requestHistories[this.requestHistories.length - 1].type,
          status_name: this.requestHistories[this.requestHistories.length - 1].status_name,
        };
      } else {
        this.selectedRequestDate = selectedRequestDateCheck[0];
      }
    }
    if (!this.isEmbedFromRequest) {
      this.getListCostsByDate(this.selectedRequestDate);
    }
  },
  mounted() {
    this.$emitter.on('reloadTripItemExpenses', () => {
      this.getListCostsByDate();
    });
  },
  beforeUnmount() {
    this.$emitter.off('reloadTripItemExpenses');
  },
  computed: {
    isShowDailyAllowance() {
      return this.$store.state.auth?.user?.setting.is_show_daily_allowance || false;
    },
    isRequestPage() {
      return window.location.pathname.includes("/request/");
    },
    calDailyAllowanceCost() {
      let totalCost = 0;
      this.allCosts.daily_allowances?.forEach(element => {
        totalCost += element.amount;
      });
      return totalCost;
    },
    calDailyAllowanceCostOld() {
      let totalCost = 0;
      this.allCostsOld.daily_allowances?.forEach(element => {
        totalCost += element.amount;
      });
      return totalCost;
    },
    requestId() {
      return this.$route.params.id || '';
    },
    groupedDailyAllowances() {
      let dailyAllList = this.allCosts.daily_allowances || [];
      let returnList = [];
      if (this.checkEmpty(dailyAllList)) return [];
      let result = this.groupBy(dailyAllList, 'daily_allowance_type');
      if (this.checkEmpty(result)) return [];
      for (let key in result) {
        result[key].forEach((detail, id) => {
          if (id == 0 && detail.amount != 0) {
            returnList.push({
              amount: detail.amount,
              daily_allowance_type: detail.daily_allowance_type,
              count: 1,
              group_type: detail.group_type,
            });
          }
          if (id > 0 && detail.amount != 0) {
            returnList.forEach(value => {
              if (value.daily_allowance_type == detail.daily_allowance_type) {
                value.amount += detail.amount;
                value.count++;
              }
            });
          }
        });
      }
      return returnList;
    },
    calInsuranceCost() {
      let totalCost = 0;
      this.allCosts.insurance?.forEach(element => {
        const countAmount = element.amount;
        totalCost += countAmount;
      });
      return totalCost;
    },
    calInsuranceCostOld() {
      let totalCost = 0;
      this.allCostsOld.insurance_histories?.forEach(element => {
        const countAmount = element.amount;
        totalCost += countAmount;
      });
      return totalCost;
    },
    calWifiRouterRentalCost() {
      let totalCost = 0;
      this.allCosts.wifi_router_rental?.forEach(element => {
        const countAmount = element.amount;
        totalCost += countAmount;
      });
      return totalCost;
    },
    calWifiRouterRentalCostOld() {
      let totalCost = 0;
      this.allCostsOld.wifi_router_rental_histories?.forEach(element => {
        const countAmount = element.amount;
        totalCost += countAmount;
      });
      return totalCost;
    },
    totalCompanyCost() {
      let total = 0;
      if (!this.checkEmpty(this.allCosts?.company_cost_items)) {
        this.allCosts.company_cost_items.forEach(item => {
          if (!this.checkEmpty(item.cost_items)) {
            item.cost_items.forEach(cost => (total += cost.amount));
          }
        });
      }
      return total;
    },
    totalCompanyCostItem() {
      let arr = [];
      if (!this.checkEmpty(this.allCosts?.company_cost_items)) {
        this.allCosts.company_cost_items.forEach(item => {
          let total = 0;
          if (!this.checkEmpty(item.cost_items)) {
            item.cost_items.forEach(cost => (total += cost.amount));
          }
          arr.push(total);
        });
      }
      return arr;
    },
    totalCompanyCostOld() {
      let total = 0;
      if (!this.checkEmpty(this.allCostsOld?.company_cost_items)) {
        this.allCostsOld.company_cost_items.forEach(item => {
          if (!this.checkEmpty(item.cost_items)) {
            item.cost_items.forEach(cost => (total += cost.amount));
          }
        });
      }
      return total;
    },
    totalEstimate() {
      let total = 0;
      if (!this.checkEmpty(this.allCosts?.cost_estimate)) {
        this.allCosts.cost_estimate.forEach(item => {
          if (!this.checkEmpty(item.cost_items)) {
            item.cost_items.forEach(cost => {
              total += cost.amount;
            });
          }
        });
      }
      return total;
    },
    totalEstimateCost() {
      let arr = [];
      if (!this.checkEmpty(this.allCosts?.cost_estimate)) {
        this.allCosts.cost_estimate.forEach(item => {
          let total = 0;
          if (!this.checkEmpty(item.cost_items)) {
            item.cost_items.forEach(cost => {
              total += cost.amount;
            });
          }
          arr.push(total);
        });
      }
      return arr;
    },
    totalEstimateCostOld() {
      let total = 0;
      if (!this.checkEmpty(this.allCostsOld?.cost_estimate)) {
        this.allCostsOld.cost_estimate.forEach(item => {
          if (!this.checkEmpty(item.cost_items)) {
            item.cost_items.forEach(cost => {
              total += cost.amount;
            });
          }
        });
      }
      return total;
    },
    calTotalCost() {
      return this.calInsuranceCost + this.totalCompanyCost + this.calDailyAllowanceCost + this.totalEstimate + this.calWifiRouterRentalCost;
    },
    calTotalCostOld() {
      return this.calInsuranceCostOld + this.totalCompanyCostOld + this.calDailyAllowanceCostOld + this.totalEstimateCostOld + this.calWifiRouterRentalCostOld;
    },
    calTotalCostDiff() {
      const costDiff = this.calTotalCost - this.calTotalCostOld;
      if (costDiff >= 0) return this.splitThousandYen(costDiff);
      return '-' + String(this.splitThousandYen(Math.abs(costDiff)));
    },
  },
  watch: {
    tripIdParams() {
      // this.getListCostsByDate();
    },
    calTotalCost(newVal) {
      this.$emit('emitTotalCost', newVal);
    },
    countLoading() {
      if (this.isRequestPage) {
        setTimeout(() => {
          this.endLoading();
        }, 1500);
      }
    },
    requestHistories: {
      handler(newVal) {
        if (!this.checkEmpty(newVal)) {
          let selectedRequestDateCheck = newVal.filter(item => {
            return item.id == this.requestId;
          });
          if (selectedRequestDateCheck.length == 0) {
            this.selectedRequestDate = {
              id: newVal[newVal.length - 1].id,
              date: newVal[newVal.length - 1].date,
              type: newVal[newVal.length - 1].type,
              status_name: newVal[newVal.length - 1].status_name,
            };
          } else {
            this.selectedRequestDate = selectedRequestDateCheck[0];
          }
        }
      },
      deep: true,
    },
    selectedRequestDate: {
      handler(newVal) {
        if (!this.checkEmpty(newVal) && this.lastRequestId != newVal?.id) {
          this.lastRequestId = newVal?.id || null;
          this.getListCostsByDate(newVal);
        }
      },
      deep: true,
    },
  },
  methods: {
    totalCompanyCostItemOld(costItem) {
      let total = 0;
      if (!this.checkEmpty(this.allCostsOld?.company_cost_items)) {
        this.allCostsOld.company_cost_items.forEach(item => {
          if (!this.checkEmpty(item.cost_items) && costItem.id === item.id) {
            item.cost_items.forEach(cost => (total += cost.amount));
          }
        });
      }
      return total;
    },
    totalCompanyCostEstimateOld(costItem) {
      let total = 0;
      if (!this.checkEmpty(this.allCostsOld?.cost_estimate)) {
        this.allCostsOld.cost_estimate.forEach(item => {
          if (!this.checkEmpty(item.cost_items) && costItem.schedule_group_type === item.schedule_group_type) {
            item.cost_items.forEach(cost => (total += cost.amount));
          }
        });
      }
      return total;
    },
    totalCostItemAmount(data) {
      let totalCost = 0;
      data.cost_items?.forEach(element => {
        totalCost += element.amount;
      });
      return totalCost;
    },
    selectDate(item) {
      this.selectedRequestDate = { id: item.id, date: item.date, type: item.type , status_name: item.status_name};
      if (this.isEmbedFromRequest) {
        this.pushToTripItem(item);
      }
      this.getListCostsByDate(this.selectedRequestDate);
    },
    changeToNewDate(item) {
      this.selectedRequestDate = {
        id: this.requestHistories[this.requestHistories.length - 1].id,
        date: this.requestHistories[this.requestHistories.length - 1].date,
        type: this.requestHistories[this.requestHistories.length - 1].type,
        status_name: this.requestHistories[this.requestHistories.length - 1].status_name,
      };
      if (this.isEmbedFromRequest) {
        this.pushToTripItem(item);
      }
    },
    pushToTripItem(item) {
      this.$router.push({
        name: this.PAGES.TRIP_ITEM,
        params: {
          id: item.id,
        },
        query: {
          date: this.showDateTime(item.date, 'YYYY/MM/DD'),
          // Approval - report or expense request
          type: item.type,
        },
      });
    },
    chooseCostRow(costDetail) {
      if (this.checkEmpty(costDetail) || !this.isEmbedFromRequest) return;
      this.selectedCost.name = costDetail?.title + '-' + String(costDetail?.cost_id);
      this.selectedCost.cost_title = costDetail?.title;
      this.selectedCost.file_url = costDetail?.file_url;
      this.getCostDetail(costDetail.cost_id);
    },
    async getScheduleByTripList() {
      await this.$services.trip.getScheduleByTripList(
          { trip_id: this.tripIdParams },
          res => {
            this.listTripSchedule = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    async getCostDetail(id, isDeleted = false) {
      if (!this.isRequestPage) {
        this.startLoading();
      }
      this.getScheduleByTripList();
      await this.$services.trip.getCostDetail(
          id,
          res => {
            if (!this.isRequestPage) {
              this.endLoading();
            }
            this.expenseCostDetail = {
              ...res,
              currency_rate: res.currency_id == 1 ? 0 : res.currency_rate,
              amount: this.splitThousandNumber(res.amount || 0),
              currency_amount: this.splitThousandNumber(res.currency_amount || 0),
              firstLoading: res.currency_id != 1 ? 1 : 0,
              is_deleted: isDeleted,
            };
            this.listFile = res.files;
            this.showExpenseCost = true;
          },
          err => {
            if (!this.isRequestPage) {
              this.endLoading();
            }
            this.notifyError(this.$t(err.error));
          },
      );
    },
    reloadData() {
      this.showExpenseCost = false;
      this.listFile = [];
      // this.getListExpense(false);
    },
    groupScheduleTypeLabel(type) {
      switch (type) {
        case 0:
          return '移動';
        case 1:
          return '宿泊';
        case 2:
          return '仕事';
        case 3:
          return 'その他';
        default:
          return '-';
      }
    },
    textToRequestType(typeId) {
      switch (typeId) {
        case 1:
          return this.$t('application.type.approval');
        case 2:
          return this.$t('application.type.report');
        case 3:
          return this.$t('application.type.expense_type');
        default:
          return '-';
      }
    },
    groupBy(array, key) {
      const result = {};
      array.forEach(item => {
        if (!result[item[key]]) {
          result[item[key]] = [];
        }
        result[item[key]].push(item);
      });
      return result;
    },
    async getListCostsByDate(selectedDate = '') {
      this.countLoading += 1;
      let params = {
        trip_id: this.tripIdParams,
      };

      if (!this.checkEmpty(selectedDate)) {
        params.request_id = selectedDate.id;
      } else if (!this.checkEmpty(this.requestId) && this.isEmbedFromRequest) {
        params.request_id = this.requestId;
      } else if (!this.checkEmpty(this.selectedRequestDate) && !this.isEmbedFromRequest) {
        params.request_id = this.selectedRequestDate.id;
      } else if (this.requestIdParams) {
        params.request_id = this.requestIdParams;
      }

      //CU-009
      if (this.tripIdParams && params.request_id) {
        if (this.isEmbedFromRequest && !this.isRequestPage) this.startLoading();
        await this.$services.trip_item.getListCostsByDate(
            params,
            res => {
              if (this.isEmbedFromRequest && !this.isRequestPage) this.endLoading();
              this.allCosts = res.list;
              this.$emit('passRequestData', res.list?.comment_request_histories || []);
            },
            err => {
              if (this.isEmbedFromRequest && !this.isRequestPage) this.endLoading();
              this.notifyError(this.$t(err.error));
            },
        );
      }

      this.getListCostsByDateOld(selectedDate);
    },
    async getListCostsByDateOld(selectedDate = '') {
      this.countLoading += 1;
      let params = {
        trip_id: this.tripIdParams,
      };
      params.flg_send_request = 1;
      if (!this.checkEmpty(selectedDate)) {
        params.request_id = selectedDate.id;
      } else if (!this.checkEmpty(this.requestId) && this.isEmbedFromRequest) {
        params.request_id = this.requestId;
      } else if (!this.checkEmpty(this.selectedRequestDate) && !this.isEmbedFromRequest) {
        params.request_id = this.selectedRequestDate.id;
      } else if (this.requestIdParams) {
        params.request_id = this.requestIdParams;
      }

      //CU-009
      if (this.tripIdParams && params.request_id) {
        if (this.isEmbedFromRequest && !this.isRequestPage) this.startLoading();
        await this.$services.trip_item.getListCostsByDate(
          params,
          res => {
            if (this.isEmbedFromRequest && !this.isRequestPage) this.endLoading();
            if (this.checkEmpty(res.list)) {
              this.flg_old_val = false;
            } else {
              this.flg_old_val = true;
            }
            this.allCostsOld = res.list;
            this.endLoading();
          },
          err => {
            if (this.isEmbedFromRequest && !this.isRequestPage) this.endLoading();
            this.notifyError(this.$t(err.error));
          },
        );
      }
    },
    textToPaymentMethod(status) {
      if (status === payment_reimbursement) return this.$t('trip_item.main_content.payment_reimbursement');
      if (status === PAYMENT_CORPORATE_CARD) return this.$t('trip_item.main_content.payment_corporate_card');
      if (status === PAYMENT_INVOICE) return this.$t('trip_item.main_content.payment_invoice');
      return '';
    },
  },
};
</script>
<style scoped>
.expence--approve--wrap .apply-expense--item.active {
  border-color: #3650a5 !important;
  background-color: rgba(54, 80, 165, 0.08) !important;
}
.main-content-trip-item {
  display: flex;
  justify-content: flex-start;
  margin-top: -48px;
}
.plan-stage {
  width: calc(100% - 470px);
}
.custom-show-ringi {
  margin-top: 0 !important;
}
#schedule-tip-item {
  margin: 0 -32px !important;
}
.apply-expense--price {
  width: 90px !important;
  max-width: 90px !important;
}
.apply-expense--section-sum {
  font-size: 14px !important;
}
.change-eki-cost {
  background-color: #8B0000;
  font-size: 10px;
  color: #fff;
  border-radius: 12px;
  padding: 2px 5px;
  margin-left: 5px;
}
.box-eki {
  margin: 0px !important;
  padding: 0px !important;
  line-height: 10px;
}
.btn-used-in-request {
  padding: 3px 7px;
  font-size: 11px;
  border-radius: 10px;
  margin-right: 5px;
}
</style>
