<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('trip.trip') }}</h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li v-if="!isOperatorOrAdmin && !userIsSB">
          <a href="#" @click="$router.push({ path: PAGES.TRIP_NEW })">
            <span class="icon-abui-plus prx"></span>{{ $t('trip.new_trip') }}
          </a>
        </li>
        <li
            v-if="!isOperatorOrAdmin && userIsSB"
            class="dropdown"
            @click="isShowDropdown = !isShowDropdown"
            v-click-outside="
            () => {
              isShowDropdown = false;
            }
          "
        >
          <a href="#" class="dropdown-toggle" aria-expanded="true">
            <span class="icon-abui-plus prx"></span>{{ $t('trip.new_trip') }}<b class="caret"></b>
          </a>
          <span class="dropdown-arrow"></span>
          <ul class="dropdown-menu" style="left: unset; right: 0" :class="isShowDropdown && 'showDropdown'">
            <li>
              <a href="#" @click="handleShowUploadSB()">{{ $t('trip_update.smart_btm_and_add') }}</a>
            </li>
            <li>
              <a href="#" @click="$router.push({ path: PAGES.TRIP_NEW })">{{ $t('trip_update.create_new') }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
  <div class="filter-wrap">
    <div class="filter js-filter--minimization" v-show="showBoxFilter">
      <div class="section section-filter noborder js-section-filter">
        <div class="row">
          <div
              class="col-sm-6 col-md-3 col-all-closer col-all-closer--leftend col-sm-closer--rightend col-xs-closer--rightend"
          >
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('common.departure_date') }}</span>
              </label>
              <div class="input-group">
                <div class="input-with-icon">
                  <el-date-picker
                      class="date-input"
                      v-model="searchParams.start_time"
                      type="date"
                      format="YYYY/MM/DD"
                      value-format="YYYY/MM/DD"
                      :placeholder="$t('common.enter_or_select')"
                      :clearable="false"
                      :disabled-date="pickerStartOptions"
                  />
                </div>
                <span class="input-group-addon prn">{{ $t('common.from') }}</span>
              </div>
            </div>
          </div>
          <div
              class="col-sm-6 col-md-3 col-all-closer col-all-closer--rightend col-sm-closer--leftend col-xs-closer--leftend"
          >
            <div class="form-group">
              <label class="hidden-xs"><span class="label-text">&nbsp;</span></label>
              <div class="input-group">
                <div class="input-with-icon">
                  <el-date-picker
                      class="date-input"
                      v-model="searchParams.end_time"
                      type="date"
                      format="YYYY/MM/DD"
                      value-format="YYYY/MM/DD"
                      :placeholder="$t('common.enter_or_select')"
                      :clearable="false"
                      :disabled-date="pickerEndOptions"
                  />
                </div>
                <span class="input-group-addon prn">{{ $t('common.to') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="form-group">
              <label>{{ $t('trip.trip') }}</label>
              <input
                  type="text"
                  v-model="searchParams.name"
                  maxlength="200"
                  class="form-control input-sm"
                  :placeholder="$t('placeholder.travel_name')"
              />
              <p class="error-feedback" v-text="errors.name"></p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="form-group">
              <label>{{ $t('trip.destination') }}</label>
              <input
                  type="text"
                  maxlength="200"
                  v-model="searchParams.destination"
                  class="form-control input-sm"
                  :placeholder="$t('placeholder.kana_kanji')"
              />
              <p class="error-feedback" v-text="errors.destination"></p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('trip.trip_type') }}</span>
              </label>
              <div class="checkbox checkbox-sm checkbox-inline mx-0 my-0">
                <el-checkbox
                    v-model="searchParams.trip_type_domestic"
                    class="pl-0"
                    :label="$t('trip.destination_type.1')"
                    size="large"
                />
                <el-checkbox
                    v-model="searchParams.trip_type_abroad"
                    class="pl-0"
                    :label="$t('trip.destination_type.2')"
                    size="large"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="form-group">
              <label>{{ $t('trip.employee') }}</label>
              <input
                  :disabled="isDisableSearchByUser"
                  type="text"
                  maxlength="30"
                  v-model="searchParams.employee"
                  class="form-control input-sm"
                  :placeholder="$t('placeholder.kana_kanji')"
              />
              <p class="error-feedback" v-text="errors.employee"></p>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('trip.organization') }}</span>
              </label>
              <MultiOptionSelect
                  ref="TripOrganizationSelect"
                  :disabled="isDisableSearchByUser"
                  :list-data="listOrganizations"
                  :defaultList="searchParams.organization_ids"
                  @handle-selected-params="listenSearchParams"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4" v-if="isShowSettingApproval">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('trip.status.approval') }}</span>
              </label>
              <el-select
                  popper-class="custom-dropdown-select"
                  v-model="searchParams.approval_status"
                  clearable
                  filterable
                  :placeholder="$t('common.not_specified')"
              >
                <el-option v-for="item in approvalStatus" :key="item.id" :label="labelLocale(item)" :value="item.id"/>
              </el-select>
            </div>
          </div>
          <div class="col-sm-4" v-if="isShowSettingApproval && isShowSettingReport">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('trip.status.reporting') }}</span>
              </label>
              <el-select
                  popper-class="custom-dropdown-select"
                  v-model="searchParams.reporting_status"
                  clearable
                  filterable
                  :placeholder="$t('common.not_specified')"
              >
                <el-option v-for="item in reportingStatus" :key="item.id" :label="labelLocale(item)" :value="item.id"/>
              </el-select>
            </div>
          </div>
          <div class="col-sm-4" v-if="showApprovalExpense">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('trip.status.expense') }}</span>
              </label>
              <el-select
                  popper-class="custom-dropdown-select"
                  v-model="searchParams.expense_status"
                  class="select-block select-sm"
                  clearable
                  filterable
                  :placeholder="$t('common.not_specified')"
              >
                <el-option
                    v-for="item in expenseStatus"
                    :key="item.id"
                    :label="labelLocale(item)"
                    :value="item.id"
                    :class="item.id == -1 && 'border-top'"
                />
              </el-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label class="checkbox checkbox-sm checkbox-inline">
                <el-checkbox
                    v-model="searchParams.is_canceled"
                    class="pl-0"
                    :label="$t('trip.is_cancel')"
                    size="large"
                />
              </label>
            </div>
          </div>
          <div class="col-sm-4" v-if="isOperatorOrAdmin">
            <div class="form-group">
              <label>
                <span class="label-text">{{ $t('common.company') }}</span>
              </label>
              <MultiOptionSelect
                  ref="CompanySelect"
                  :list-data="listCompanies"
                  :defaultList="searchParams.company"
                  @handle-selected-params="listenSearchParamCompany"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.filter -->

    <div class="section-submit js-section-submit" v-show="showBoxFilter">
      <span class="submit-arrow"></span>
      <div class="row">
        <div class="col-sm-4 col-md-5 hidden-xs">
          <button class="btn btn-sm btn-flat-default btn-clear" @click="clearFilter()">{{ $t('common.clear') }}</button>
        </div>
        <div class="col-sm-4 col-md-2">
          <button class="btn btn-sm btn-primary btn-block js-btn-filter--minimization" @click="handleSearch()">
            {{ $t('common.search') }}
          </button>
        </div>
        <div class="col-sm-4 col-md-5 hidden-xs">
          <button class="btn btn-sm btn-flat-default btn-close" @click="showBoxFilter = false">
            {{ $t('common.close') }}
          </button>
        </div>
      </div>
    </div>
    <!-- /.section-submit -->

    <div
        class="btn-filter-again with-condition"
        v-show="!showBoxFilter"
        @click="showBoxFilter = true"
        style="display: block"
    >
      <div class="filter--condition">
        <span>{{ $t('common.all') }}</span>
        <span style="color: #3650a5">{{ $t('common.reset_conditions') }}</span>
      </div>
    </div>

    <div class="filter-result js-filter-result--minimization">
      <div class="section noborder">
        <Pagination
            v-if="listTrips.length > 0"
            :paginate="paginate"
            @change-page="changePageData"
            @change-number="changeNumber"
        />

        <div>
          <div class="table-responsive">
            <table class="table table-hover table-hover--pointer table--property-list" id="table--plan">
              <thead>
              <draggable v-model="headers" tag="tr" @end="saveColumns" :class="{'grabbing': isDragging}"
                         @start="onStart" :force-fallback="true">
                <th class="table-sort"
                    v-for="header in headers"
                    :key="header"
                    :class="[sortActive.column == header && 'table-sort-active', classHeaders[header]]"
                    @click="sortAction(header)">
                    <span :class="sortActive.column == header ? sortActive.by : 'asc'"
                          v-if="!['location', 'status_deleted'].includes(header)"
                    >
                      {{ $t(nameHeaders[header]) }}
                    </span>
                </th>
              </draggable>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in listTrips"
                  :key="index"
                  @click="
                    this.$router.push({
                      name: this.PAGES.TRIP_UPDATE,
                      params: {
                        id: item.id,
                      },
                    })
                  "
              >
                <td v-for="header in headers" :key="header">
                  <div v-if="header == 'approval_or_report'" :style="{
                      color: item?.report_status ? item?.report_status?.color : item?.approval_status?.color,
                    }">
                    {{ item.report_status ? labelLocale(item.report_status) : labelLocale(item.approval_status) }}
                  </div>
                  <div v-if="header == 'expense_column' && showApprovalExpense" :style="{
                      color: checkEmpty(item.expense_settlement) ? '' : item.expense_settlement.color,
                    }">
                    {{ !checkEmpty(item.expense_settlement) ? labelLocale(item.expense_settlement) : '-' }}
                  </div>
                  <div v-if="header == 'id'">
                    {{ item.code }}
                  </div>
                  <div v-if="header == 'location' && item.number_schedule > 0">
                    {{ getDestinationTypeLabel(item.destination_type) }}
                  </div>
                  <div v-if="header == 'user_name'" class="td-100p">
                    <el-tooltip
                        v-if="!checkEmpty(item.user_name) && item.user_id != userId && calTextWidth(item.user_name) > 90"
                        popper-class="tool-tip-guide custom-tooltip-title"
                        class="label-icon-guide"
                        effect="dark"
                        :content="item.user_name"
                        :visible-arrow="false"
                        placement="top-start"
                    >
                      <span class="td-100p"> {{ item.user_name }}</span>
                    </el-tooltip>
                    <span v-else>
                      {{ item.user_id == userId ? $t('application.myself') : item.user_name }}
                    </span>
                  </div>
                  <div v-if="header == 'company_sort' && isOperatorOrAdmin" class="td-200p">
                    <el-tooltip
                        v-if="!checkEmpty(item?.company?.name) && calTextWidth(item?.company?.name) > 180"
                        popper-class="tool-tip-guide custom-tooltip-title"
                        class="label-icon-guide"
                        effect="dark"
                        :content="item?.company?.name"
                        :visible-arrow="false"
                        placement="top-start"
                    >
                      <span class="td-200p">{{ item?.company?.name }}</span>
                    </el-tooltip>
                    <span v-else>
                      {{ item?.company?.name }}
                    </span>
                  </div>
                  <div v-if="header == 'name'" class="td-250p">
                    <el-tooltip
                        v-if="!checkEmpty(item.name) && calTextWidth(item.name) > 230"
                        popper-class="tool-tip-guide custom-tooltip-title"
                        class="label-icon-guide"
                        effect="dark"
                        :content="item.name"
                        :visible-arrow="false"
                        placement="top-start"
                    >
                      <span :ref="`title-expense-${item.id}`" class="td-250p">{{ item.name }}</span>
                    </el-tooltip>
                    <span v-else :ref="`title-expense-${item.id}`">
                      {{ !checkEmpty(item.name) ? item.name : '-' }}
                    </span>
                  </div>
                  <div v-if="header == 'start_time'">
                    {{ item.start_time && showDateTime(item.start_time, 'YYYY/MM/DD（ddd）') }} -
                    {{ item.end_time && showDateTime(item.end_time, 'YYYY/MM/DD（ddd）') }}
                  </div>
                  <div v-if="header == 'destination'" class="td-250p">
                    <el-tooltip
                        v-if="!checkEmpty(item.location_string) && calTextWidth(item.location_string) > 230"
                        popper-class="tool-tip-guide custom-tooltip-title"
                        class="label-icon-guide"
                        effect="dark"
                        :content="item.location_string"
                        :visible-arrow="false"
                        placement="top-start"
                    >
                      <span class="td-250p">{{ item.location_string }}</span>
                    </el-tooltip>
                    <span v-else>
                     {{ item.location_string }}
                    </span>
                  </div>
                  <div v-if="header == 'status_deleted'" :style="{
                      color: item?.report_status ? item?.report_status?.color : item?.approval_status?.color,
                    }">
                    {{ showLabelTraveling(item) }}
                  </div>
                </td>

              </tr>
              </tbody>
            </table>
            <p v-if="listTrips.length == 0" class="no-data">{{ $t('common.no_data_trip') }}</p>
          </div>
        </div>

        <Pagination
            v-if="listTrips.length > 0"
            @change-page="changePageData"
            :paginate="paginate"
            @change-number="changeNumber"
        />
      </div>
    </div>
  </div>

  <ModalListTripSB
      :dialogVisible="showUploadTripSB"
      :listTripSB="listTripSB"
      :showConfirmPopUp="true"
      @cancel="showUploadTripSB = false"
      @success="showConfirmUploadSB"
  />
  <ModalConfirm
      :dialogVisible="showBoxConfirm"
      classCustom="btn-primary"
      @cancel="(showBoxConfirm = false), (showUploadTripSB = true)"
      @yes="syncTrip"
      :title="$t('customer.importing_from_sb')"
  />
</template>
<script>
import Pagination from '@/components/common/Pagination';
import MultiOptionSelect from '@/components/common/MultiOptionSelect.vue';
import ModalListTripSB from '@/components/common/ModalListTripSB';
import ModalConfirm from '@/components/common/ModalConfirm';
import moment from 'moment';
import mixins from '@/helpers/mixins';
import vClickOutside from 'click-outside-vue3';
import {VueDraggableNext} from 'vue-draggable-next';
import {ORDER_TYPE_TRIP_LIST} from '@/constants';

export default {
  name: 'Trip',
  mixins: [mixins],
  components: {
    Pagination,
    MultiOptionSelect,
    ModalListTripSB,
    ModalConfirm,
    draggable: VueDraggableNext
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      isDragging: false, // Initialize the isDragging state
      nameHeaders: {
        approval_or_report: "application.table.approval_or_report",
        expense_column: "application.table.expense_column",
        id: "trip.id",
        location: "",
        user_name: "trip.user_name",
        company_sort: "common.company",
        name: "trip.name",
        start_time: "trip.start_time",
        destination: "trip.destination",
        status_deleted: "",
      },
      classHeaders: {
        approval_or_report: "mw-200p",
        expense_column: "width-125-per",
        id: "width-125-per",
        location: "width-2em",
        user_name: "mw-100p",
        company_sort: "mw-200p",
        name: "mw-250p",
        start_time: "width-15-per",
        destination: "mw-250p",
        status_deleted: "",
      },
      headers: this.tripOrderData || [
        "approval_or_report",
        "expense_column",
        "id",
        "location",
        "user_name",
        "company_sort",
        "name",
        "start_time",
        "destination",
        "status_deleted",
      ],
      showBoxConfirm: false,
      currentSbItem: {},
      showUploadTripSB: false,
      isShowDropdown: false,
      sortActive: {
        column: this.$route.query.sort_column || 'id',
        by: this.$route.query.sort_by || 'dsc',
      },
      errors: {},
      showBoxFilter: false,
      listTrips: [],
      paginate: {
        page: +this.$route.query.page || 1,
        per_page: +this.$route.query.per_page || 10,
      },
      approvalStatus: [],
      reportingStatus: [],
      expenseStatus: [],
      listTripSB: [],
      listOrganizations: [],
      listCompanies: [],
      searchParams: {
        start_time: this.$route.query.start_time || '',
        end_time: this.$route.query.end_time || '',
        name: this.$route.query.name || '',
        destination: this.$route.query.destination || '',
        employee: this.$route.query.employee || '',
        organization_ids: this.$route.query.organization_ids ? JSON.parse(this.$route.query.organization_ids) : [],
        company: this.$route.query.company ? JSON.parse(this.$route.query.company) : [],
        expense_status: +this.$route.query.expense_status || '',
        reporting_status: +this.$route.query.reporting_status || '',
        approval_status: +this.$route.query.approval_status || '',
        trip_type_domestic: this.$route.query?.trip_type_domestic ? this.$route.query.trip_type_domestic == 1 : false,
        trip_type_abroad: this.$route.query?.trip_type_abroad ? this.$route.query.trip_type_abroad == 1 : false,
        is_canceled: this.$route.query?.is_canceled ? this.$route.query.is_canceled == 1 : false,
      },
    };
  },
  computed: {
    showApprovalExpense() {
      return this.$store.state.auth?.user?.setting?.is_feature_payment || false;
    },
    isShowSettingApproval() {
      return this.$store.state.auth?.user?.setting.is_show_approval || false;
    },
    isShowSettingReport() {
      return this.$store.state.auth?.user?.setting.is_show_report || false;
    },
    tripOrderData() {
      return this.$store.state.auth?.user?.order_setting?.trip || [
        "approval_or_report",
        "expense_column",
        "id",
        "location",
        "user_name",
        "company_sort",
        "name",
        "start_time",
        "destination",
        "status_deleted",
      ];
    },
    userId() {
      return this.$store.state.auth?.user?.id || '';
    },
    userName() {
      return this.$store.state.auth?.user?.name || '';
    },
    userRole() {
      return this.$store.state.auth?.user?.role?.id || '';
    },
    userOrganizationId() {
      return this.$store.state.auth?.user?.parent_organization_id || '';
    },
    isDisableSearchByUser() {
      if (!this.checkEmpty(this.userRole) && this.userRole != 1) return false;
      return true;
    },
  },
  watch: {
    userRole() {
      // if (!this.isOperatorOrAdmin) this.searchParams.employee = this.userName;
      if (!this.checkEmpty(this.userOrganizationId) && this.checkEmpty(this.searchParams.organization_ids)) {
        this.searchParams.organization_ids = [];
        this.searchParams.organization_ids.push(this.userOrganizationId);
      }
    },
    tripOrderData() {
      if (this.tripOrderData) {
        this.headers = this.tripOrderData;
        this.filterHeaderData();
      }
    },
    searchParams: {
      handler: function (newValue) {
        if (this.strLen(newValue.name) > 200) {
          this.errors.name = this.$t('E-CM-111', {size: 200});
        } else {
          delete this.errors.name;
        }

        if (this.strLen(newValue.destination) > 200) {
          this.errors.destination = this.$t('E-CM-111', {size: 200});
        } else {
          delete this.errors.destination;
        }

        if (this.strLen(newValue.employee) > 30) {
          this.errors.employee = this.$t('E-CM-111', {size: 30});
        } else {
          delete this.errors.employee;
        }
      },
      deep: true,
    },
    isOperatorOrAdmin(val) {
      if (val) this.getListCompanies();
    },
    'searchParams.start_time'(newVal) {
      if (this.checkEmpty(this.searchParams.end_time)) this.searchParams.end_time = newVal;
    },
  },
  created() {
    if (this.isOperatorOrAdmin) this.getListCompanies();
    if (this.userIsSB) this.getListTripSB();
    this.startLoading();
    Promise.all([
      this.initData(),
      this.filterHeaderData(),
      this.getListStatusApproval(),
      this.getListStatusReport(),
      this.getListStatusExpense(),
      this.getListOrganizations(),
    ]).then(() => {
      this.getListTrips();
      this.endLoading();
    });
    this.setApprovalOrReport();
  },
  methods: {
    setApprovalOrReport() {
      if (this.isShowSettingApproval && !this.isShowSettingReport) {
        this.nameHeaders.approval_or_report = "application.table.approval";
      } else if (!this.isShowSettingApproval && this.isShowSettingReport) {
        this.nameHeaders.approval_or_report = "application.table.report";
      }
    },
    filterHeaderData() {
      if (!this.isOperatorOrAdmin) {
        this.headers = this.headers.filter(item => {
          return item != 'company_sort'
        });
      }
      if (!this.showApprovalExpense) {
        this.headers = this.headers.filter(item => {
          return item != 'expense_column'
        });
      }
      if (!this.isShowSettingApproval) {
        this.headers = this.headers.filter(item => {
          return item != 'approval_or_report'
        });
      }
    },
    onStart() {
      this.isDragging = true
    },
    async saveColumns() {
      this.isDragging = false
      let dataHeader = [...this.headers];
      if (!this.headers.includes('expense_column')) {
        var index = this.tripOrderData.indexOf('expense_column');
        dataHeader.splice(index, 0, 'expense_column');
      }
      if (!this.headers.includes('approval_or_report')) {
        var index2 = this.tripOrderData.indexOf('approval_or_report');
        dataHeader.splice(index2, 0, 'approval_or_report');
      }
      this.startLoading();
      await this.$services.account.saveOrderColumn(
          {
            content: dataHeader,
            type: ORDER_TYPE_TRIP_LIST
          },
          () => {
            this.filterHeaderData();
            this.endLoading();
          },
          () => {
            this.endLoading();
          },
      );
    },
    async initData() {
      await this.$services.account.current(
          res => {
            this.$store.dispatch('auth/initCurrentData', res);
          },
          () => {
            this.logout();
          },
      );
      if (this.checkEmpty(this.searchParams.employee) && !this.isOperatorOrAdmin)
        this.searchParams.employee = this.userName || '';
      if (!this.checkEmpty(this.userOrganizationId) && this.checkEmpty(this.searchParams.organization_ids)) {
        this.searchParams.organization_ids = [];
        this.searchParams.organization_ids.push(this.userOrganizationId);
      }
    },
    calTextWidth(inputText = '') {
      let font = '14px SpicaNeueP-Regular';
      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');
      context.font = font;
      return context.measureText(inputText).width;
    },
    async handleShowUploadSB() {
      await this.getListTripSB(true);
      this.showUploadTripSB = true;
    },
    async getListTripSB(showLoading = false) {
      if (showLoading) this.startLoading();
      await this.$services.trip.getListTripSB(
          res => {
            if (showLoading) this.endLoading();
            this.listTripSB = res.list;
          },
          () => {
            if (showLoading) this.endLoading();
          },
      );
    },
    showConfirmUploadSB(item) {
      this.currentSbItem = item;
      this.showBoxConfirm = true;
      this.showUploadTripSB = false;
    },
    syncTrip(item = this.currentSbItem) {
      if (item?.itineraryId) {
        this.startLoading();
        this.$services.trip.syncTripFromSB(
            {
              trip_id: item?.itineraryId,
            },
            res => {
              this.showUploadTripSB = false;
              if (res?.id)
                this.$router.push({
                  name: this.PAGES.TRIP_UPDATE,
                  params: {
                    id: res?.id,
                  },
                });
            },
            () => {
              this.endLoading();
            },
        );
      }
    },
    tripCode(trip) {
      if (!this.checkEmpty(trip.expense_settlement?.code)) {
        return trip.expense_settlement?.code;
      }

      if (!this.checkEmpty(trip.report_status?.code)) {
        return trip.report_status?.code;
      }

      return trip.approval_status?.code;
    },
    showLabelTraveling(trip) {
      let flg = false;
      if (this.checkEmpty(trip.start_time) || this.checkEmpty(trip.end_time)) {
        return '';
      }

      if (
          moment(new Date()).format('YYYY-MM-DD') >= moment(trip.start_time).format('YYYY-MM-DD') &&
          moment(new Date()).format('YYYY-MM-DD') <= moment(trip.end_time).format('YYYY-MM-DD')
      )
        flg = true;

      if (flg) return this.$t('trip.traveling');
      return '';
    },
    showLabel(trip) {
      const code = this.tripCode(trip);

      if (
          [
            'approval_no_applied',
            'approval_no_need',
            'approval_waiting',
            'approval_pending',
            'approval_rejected',
            'approval_approval',
            'approval_changed',
            'report_no_report',
            'report_no_report_change',
            'report_waiting',
            'report_pending',
            'report_rejected',
            'report_approved',
            'report_cancel',
          ].includes(code)
      )
        return '-';

      if (['expense_waiting', 'expense_pending', 'expense_rejected', 'expense_approved'].includes(code))
        return this.labelLocale(trip.expense_settlement);

      return '';
    },
    listenSearchParams(selectedList) {
      this.searchParams.organization_ids = selectedList;
    },
    listenSearchParamCompany(selectedList) {
      this.searchParams.company = selectedList;
    },
    sortAction(name) {
      if (!['location', 'status_deleted'].includes(name)) {
        this.sortActive.column = name;
        if (this.sortActive.by == 'dsc') this.sortActive.by = 'asc';
        else this.sortActive.by = 'dsc';

        this.getListTrips();
      }
    },
    pickerStartOptions(time) {
      if (!this.searchParams.end_time) return false;

      return moment(new Date(time)).format('YYYY-MM-DD') > moment(this.searchParams?.end_time).format('YYYY-MM-DD');
    },
    pickerEndOptions(time) {
      if (!this.searchParams.start_time) return false;

      return moment(new Date(time)).format('YYYY-MM-DD') < moment(this.searchParams?.start_time).format('YYYY-MM-DD');
    },
    changePageData(page) {
      this.paginate.page = +page;
      this.getListTrips();
    },
    handleSearch() {
      if (!this.checkEmpty(this.errors)) return;

      this.paginate.page = 1;
      this.getListTrips();
    },
    async getListTrips() {
      const dataSearch = {
        ...this.searchParams,
        expense_status: this.searchParams.expense_status,
        reporting_status: this.searchParams.reporting_status,
        approval_status: this.searchParams.approval_status,
        page: this.paginate.page,
        per_page: this.paginate.per_page,
        sort_column: this.sortActive.column,
        sort_by: this.sortActive.by == 'dsc' ? 'desc' : 'asc',
      };

      if (this.$i18n.locale != 'ja') {
        dataSearch.locale = this.$i18n.locale;
      }

      let queryBuild = '';
      if (this.searchParams.trip_type_domestic) queryBuild += 'trip_type[]=1&';
      if (this.searchParams.trip_type_abroad) queryBuild += 'trip_type[]=2&';

      if (!this.checkEmpty(this.searchParams.organization_ids)) {
        this.searchParams.organization_ids.forEach(item => {
          queryBuild += 'organization_ids[]=' + item + '&';
        });
      }

      delete dataSearch.organization_ids;
      delete dataSearch.trip_type_domestic;
      delete dataSearch.trip_type_abroad;

      if (this.isOperatorOrAdmin) {
        if (this.searchParams.company.length > 0) {
          this.searchParams.company.forEach(item => {
            queryBuild += 'company[]=' + item + '&';
          });
        }
        delete dataSearch.company;

        await this.$services.trip.getListTripsOP(
            dataSearch,
            queryBuild,
            res => {
              this.listTrips = res.list;
              this.paginate = {...this.paginate, ...res.paginate};
              this.showBoxFilter = false;
              this.updateRouter(res, true);
              this.endLoading();
            },
            err => {
              this.endLoading();
              this.notifyError(this.$t(err.error));
            },
        );
        this.endLoading();
      } else {
        delete dataSearch.company;

        await this.$services.trip.getListTrips(
            dataSearch,
            queryBuild,
            res => {
              this.listTrips = res.list;
              this.paginate = {...this.paginate, ...res.paginate};
              this.showBoxFilter = false;
              this.updateRouter(res);
              this.endLoading();
            },
            err => {
              this.endLoading();
              this.notifyError(this.$t(err.error));
            },
        );

        this.endLoading();
      }
    },
    updateRouter(res, isOperatorOrAdmin = false) {
      let query = {
        page: res.paginate.current_page,
        per_page: res.paginate.per_page,
        start_time: this.searchParams.start_time,
        end_time: this.searchParams.end_time,
        name: this.searchParams.name,
        destination: this.searchParams.destination,
        employee: this.searchParams.employee,
        organization_ids: JSON.stringify(this.searchParams.organization_ids),
        expense_status: this.searchParams.expense_status,
        reporting_status: this.searchParams.reporting_status,
        approval_status: this.searchParams.approval_status,
        trip_type_domestic: this.searchParams.trip_type_domestic ? 1 : 0,
        trip_type_abroad: this.searchParams.trip_type_abroad ? 1 : 0,
        is_canceled: this.searchParams.is_canceled ? 1 : 0,
        sort_column: this.sortActive.column,
        sort_by: this.sortActive.by,
      };

      if (isOperatorOrAdmin) query.company = JSON.stringify(this.searchParams.company);
      query = this.removeEmptyObject(query);

      this.$router.push({
        path: this.$route.path,
        query,
      });
    },
    async getListStatusApproval() {
      await this.$services.common.getListStatusApproval(
          {},
          res => {
            this.approvalStatus = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    async getListStatusReport() {
      await this.$services.common.getListStatusReport(
          {},
          res => {
            this.reportingStatus = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    async getListStatusExpense() {
      await this.$services.common.getListStatusExpense(
          {with_other: 1},
          res => {
            this.expenseStatus = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    async getListOrganizations() {
      this.$services.application.getListParentOrganizations(
          {},
          res => {
            this.listOrganizations = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    async getListCompanies() {
      const params = {
        all_data: true,
      };
      await this.$services.common.getListCompanies(
          params,
          res => {
            this.listCompanies = res.list;
          },
          err => {
            this.notifyError(this.$t(err.error));
          },
      );
    },
    changeNumber(number) {
      this.paginate = {
        ...this.paginate,
        page: 1,
        per_page: number,
      };
      this.getListTrips();
    },
    clearFilter() {
      this.searchParams = {
        organization_ids: [],
        company: [],
        is_canceled: false,
        trip_type_domestic: false,
        trip_type_abroad: false,
      };
      this.$refs.TripOrganizationSelect.clearFilter();
      if (this.isOperatorOrAdmin) this.$refs.CompanySelect.clearFilter();
      this.initData();
    },
  },
};
</script>

<style scoped>
.el-tooltip__trigger {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
