export const CHECKING_EN = 'Checking';
export const CHECKING_JA = '確認中';

export const CONFIRMED_COMPLETED_EN = 'Confirmation completed';
export const CONFIRMED_COMPLETED_JA = '確認完了';

export const SAFE_INFO_UNANSWERED = 1;
export const SAFE_INFO_ANSWER_SAFE = 2;
export const SAFE_INFO_ANSWER_NO_SAFE = 3;
export const SAFE_INFO_CONFIRM_UNNECESSARY = 4;
export const SAFE_INFO_CONFIRM_OUTSIDE_THE_APP = 5;
export const SAFE_INFO_UNSEND_TYPE = 6;

export const SAFE_INFO_LOCATION_OVERSEAS = 'LocationOverseas';
