<template>
  <div class="login">
    <div class="login-screen container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-md-offset-2 col-lg-offset-3">
          <div class="login-icon">
            <h4><span style="text-transform: uppercase">Travel Manager</span></h4>
          </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-md-offset-2 col-lg-offset-3">
          <Form class="login-form" @submit="handleUpdate" :validation-schema="validate">
            <div class="form-group">
              <Field
                name="employee_number"
                type="text"
                class="form-control login-field"
                readonly
                v-model="valueRequest.employee_number"
              />
            </div>
            <div class="form-group">
              <div class="input-with-icon">
                <Field
                  name="new_password"
                  type="password"
                  class="form-control login-field"
                  :placeholder="$t('update_password.place_new_password')"
                  v-model="valueRequest.new_password"
                  maxlength="50"
                  autocomplete="off"
                />
                <span class="input-icon icon-abui-lock"></span>

                <ErrorMessage name="new_password" class="error-feedback" as="p" />
              </div>
            </div>
            <div class="form-group">
              <div class="input-with-icon">
                <Field
                  name="confirm_password"
                  type="password"
                  class="form-control login-field"
                  :placeholder="$t('update_password.place_confirm_password')"
                  v-model="valueRequest.confirm_password"
                  maxlength="50"
                  autocomplete="off"
                />
                <span class="input-icon icon-abui-lock"></span>

                <ErrorMessage name="confirm_password" class="error-feedback" as="p" />
              </div>
            </div>

            <p class="error-feedback" v-text="errors_message.update_password && $t(errors_message.update_password)"></p>

            <button class="btn btn-primary btn-block js--btn-login" :disabled="disabledSubmit">
              {{ $t('update_password.submit') }}
            </button>
            <div>
              <a class="login-link" @click="$router.push(PAGES.LOGIN)" href="#">
                {{ $t('update_password.back_to_login') }}
              </a>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import moment from 'moment';

export default {
  name: 'UpdatePassword',
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      errors_message: {
        update_password: '',
      },
      valueRequest: {
        employee_number: this.$route.query.user_id || '',
        new_password: '',
        confirm_password: '',
      },
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    token() {
      return this.$route.query.token;
    },
    disabledSubmit() {
      return (
        !this.valueRequest.employee_number.trim() ||
        !this.valueRequest.new_password.trim() ||
        !this.valueRequest.confirm_password.trim() ||
        this.valueRequest.new_password.trim() != this.valueRequest.confirm_password.trim()
      );
    },
    validate() {
      return yup.object().shape({
        employee_number: yup.string().trim().required(this.$t('E-CM-004')),
        new_password: yup.string().trim().required(this.$t('E-CU-115'))
          .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, this.$t('E-CU-118')),
        confirm_password: yup
          .string()
          .trim()
          .when('new_password', (new_password, schema) => {
            if (!new_password) return schema.required(this.$t('E-CU-116'));
          })
          .oneOf([yup.ref('new_password')], this.$t('E-CU-117')),
      });
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push(this.PAGES.HOME);
    }
    if (!this.token || !this.$route.query.user_id) {
      this.$router.push(this.PAGES.LOGIN);
    }
  },
  methods: {
    handleUpdate(user) {
      this.startLoading();

      this.errors_message = {
        update_password: '',
      };

      user.token = this.token;

      if (this.$route.query.is_first_login) {
        user.is_first_login = 1;
      }
      if (!this.checkEmpty(this.$route.query.user_identifier)) {
        user.user_id = this.$route.query.user_identifier;
      }

      this.$services.account.updatePassword(
        user,
        res => {
          this.endLoading();

          if (!this.checkEmpty(res.token) && this.$route.query.is_first_login) {
            this.$store.dispatch('auth/loginSuccess', {}).then(() => {
              this.saveAccessToken(res.token);
              if (!this.checkEmpty(res.expires_at)) {
                const expiredDateTime = moment().add(res.expires_at, 'seconds').format('YYYY/MM/DD-HH:mm:ss');
                this.saveExpiredDateTime(expiredDateTime);
              }
              if (!this.checkEmpty(res.user_id)) this.saveUserId(res.user_id);
              if (!this.checkEmpty(res.role_id) && [6, 7].includes(res.role_id)) {
                window.location = this.PAGES.INPUT;
              } else {
                window.location = this.PAGES.DASHBOARD;
              }
            });
          } else {
            this.$router.push(this.PAGES.LOGIN);
          }
        },
        err => {
          this.errors_message = {
            ...this.errors_message,
            update_password: err.error,
          };
          this.endLoading();
        },
      );
    },
  },
};
</script>
