<template>
  <div class="section noborder" style="height: 800px; padding: 0px !important">
    <GMapMap
      v-if="!checkEmpty(listMarker)"
      :center="!checkEmpty(listMarker[0]?.position) ? listMarker[0]?.position : center"
      :options="options"
      :zoom="3"
      :key="componentKey"
      map-type-id="terrain"
      style="height: 100vh"
    >
      <div v-for="(marker, index) in listMarker" :key="index">
        <gmap-custom-marker :marker="!checkEmpty(marker.position) ? marker.position : center">
          <div v-if="marker.answer_type == 2" class="security--map focused">
            <div class="security--map--people focus done" :class="!checkEmpty(marker.position) && 'gps'">
              <span class="people--gps" v-if="marker.isGPS">
                <i class="icon-abui-gps" />
              </span>
              <div
                class="baloon dropshadow--lv2 answer--ok"
                :style="checkEmpty(marker.answer_message) && 'background-color: #d0d6da'"
              >
                <span class="baloon--inner">
                  {{ !checkEmpty(marker.answer_message) ? marker.answer_message : $t('security.no_comment') }}
                </span>
              </div>
              <div class="name--wrap dropshadow--lv1">
                <span class="name">
                  {{ marker.member_name ? marker.member_name : marker.full_name ? marker.full_name : '' }}
                  <span class="phone--people">{{ marker.phone }}</span>
                </span>
                <button class="btn btn-sm btn-flat-default" @click="$emit('showEmergencyContact', marker)">
                  <i class="icon-abui-bullhorn" />
                </button>
              </div>
            </div>
          </div>
          <div v-else-if="marker.answer_type == 3" class="security--map focused">
            <div class="security--map--people focus done ng" :class="!checkEmpty(marker.position) && 'gps'">
              <span class="people--gps" v-if="marker.isGPS">
                <i class="icon-abui-gps" />
              </span>
              <div
                class="baloon dropshadow--lv2 answer--ok"
                :style="checkEmpty(marker.answer_message) && 'background-color: #d0d6da'"
              >
                <span class="baloon--inner">
                  {{ !checkEmpty(marker.answer_message) ? marker.answer_message : $t('security.no_comment') }}
                </span>
              </div>
              <div class="name--wrap dropshadow--lv1">
                <span class="name">
                  {{ marker.member_name ? marker.member_name : marker.full_name ? marker.full_name : '' }}
                  <span class="phone--people">{{ marker.phone }}</span>
                </span>
                <button class="btn btn-sm btn-flat-default" @click="$emit('showEmergencyContact', marker)">
                  <i class="icon-abui-bullhorn" />
                </button>
              </div>
            </div>
          </div>
          <div v-else class="security--map focused">
            <div class="security--map--people focus" :class="!checkEmpty(marker.position) && 'gps'">
              <span class="people--gps" v-if="marker.isGPS">
                <i class="icon-abui-gps" />
              </span>
              <button
                v-if="!isOperatorOrAdmin && marker.answer_type != 4"
                class="btn btn-sm btn-primary btn-locate open-alert"
                @click="sendBroadcastToSingleUser"
              >
                {{ this.$t('security.send_safety_confirmation') }}
              </button>
              <div class="name--wrap dropshadow--lv1">
                <span class="name">
                  {{ marker.member_name ? marker.member_name : marker.full_name ? marker.full_name : '' }}
                  <span class="phone--people">{{ marker.phone }}</span>
                </span>
                <button class="btn btn-sm btn-flat-default" @click="$emit('showEmergencyContact', marker)">
                  <i class="icon-abui-bullhorn" />
                </button>
              </div>
            </div>
          </div>
        </gmap-custom-marker>
      </div>
    </GMapMap>
    <GMapMap
      v-else
      :center="!checkEmpty(targetCountry) ? targetCountry : center"
      :options="options"
      :zoom="3"
      map-type-id="terrain"
      style="height: 100vh"
    >
      <div v-for="(marker, index) in listTargetPersonWithMarker" :key="index">
        <gmap-custom-marker :marker="!checkEmpty(marker.position) ? marker.position : center">
          <div class="security--map focused">
            <div class="security--map--people focus" :class="!checkEmpty(marker.position) && 'gps'">
              <div class="name--wrap dropshadow--lv1">
                <span class="name">
                  {{ marker.member_name ? marker.member_name : marker.full_name ? marker.full_name : '' }}
                  <span class="phone--people">{{ !checkEmpty(marker.phone) ? marker.phone : '' }}</span>
                </span>
                <button class="btn btn-sm btn-flat-default" @click="$emit('showEmergencyContact', marker)">
                  <i class="icon-abui-bullhorn" />
                </button>
              </div>
            </div>
          </div>
        </gmap-custom-marker>
      </div>
    </GMapMap>
  </div>
</template>
<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker';
export default {
  name: 'Map',
  components: {
    'gmap-custom-marker': GmapCustomMarker,
  },
  emits: ['showEmergencyContact'],
  props: {
    listMembers: {
      type: [Array, Object],
      default: () => [] || {},
    },
    selectedMember: {
      type: Object,
      default: () => {},
    },
    countrySafety: {
      type: Array,
      default: () => [],
    },
    isDisplayMap: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: {
        disableDefaultUI: true,
        minZoom: 3,
        maxZoom: 12,
      },
      listData: [],
      markerSelected: [],
      center: {
        lat: 35.6762,
        lng: 139.6503,
      },
      listTargetPersonWithMarker: [],
      listCountry: [],
      targetCountry: {},
      ctrTarget: {},
      componentKey: 1,
    };
  },
  computed: {
    companyAddress() {
      return this.$store.state.auth?.user?.company?.address || '';
    },
    listMarker() {
      let arrMarker = [];
      if (!this.checkEmpty(this.selectedMember) && this.isDisplayMap) {
        if (!this.checkEmpty(this.selectedMember.gps_confirm_safety_info)) {
          let marker = {
            ...this.selectedMember,
            position: {
              lat: Number(this.selectedMember.gps_confirm_safety_info.coordinate.lat),
              lng: Number(this.selectedMember.gps_confirm_safety_info.coordinate.lng),
            },
            isGPS: true,
          };
          arrMarker.push(marker);
        }

        if (!this.checkEmpty(this.selectedMember.gps_map_data)) {
          let marker = {
            ...this.selectedMember,
            position: {
              lat: Number(this.selectedMember.gps_map_data.coordinate.lat),
              lng: Number(this.selectedMember.gps_map_data.coordinate.lng),
            },
            isGPS: true,
          };
          arrMarker.push(marker);
        }

        if (!this.checkEmpty(this.selectedMember.over_seas_data)) {
          let marker = {
            ...this.selectedMember,
            position: {
              lat: Number(this.selectedMember.over_seas_data.coordinate.lat),
              lng: Number(this.selectedMember.over_seas_data.coordinate.lng),
            },
          };
          arrMarker.push(marker);
        }

        if (!this.checkEmpty(this.selectedMember.schedule_data)) {
          this.selectedMember.schedule_data.forEach(schedule => {
            if (!this.checkEmpty(schedule.arrival_coordinate)) {
              let marker = {
                ...this.selectedMember,
                position: {
                  lat: Number(schedule.arrival_coordinate.lat),
                  lng: Number(schedule.arrival_coordinate.lng),
                },
              };
              arrMarker.push(marker);
            }
            if (!this.checkEmpty(schedule.departure_coordinate)) {
              let marker = {
                ...this.selectedMember,
                position: {
                  lat: Number(schedule.departure_coordinate.lat),
                  lng: Number(schedule.departure_coordinate.lng),
                },
              };
              arrMarker.push(marker);
            }
          });
        }
      } else if (
        !this.checkEmpty(this.selectedMember) &&
        !this.checkEmpty(this.selectedMember.gps_confirm_safety_info)
      ) {
        let marker = {
          ...this.selectedMember,
          position: {
            lat: Number(this.selectedMember.gps_confirm_safety_info.coordinate.lat),
            lng: Number(this.selectedMember.gps_confirm_safety_info.coordinate.lng),
          },
          isGPS: true,
        };
        arrMarker.push(marker);
      }

      return arrMarker;
    },
  },
  watch: {
    companyAddress() {
      this.getDefaultCenterMap();
    },
    selectedMember() {
      this.componentKey += 1;
    },
    listMembers() {
      this.listData = JSON.parse(JSON.stringify(this.listMembers));
      this.listTargetPersonWithMarker = [];
      this.listMembers.forEach(person => {
        if (!this.checkEmpty(person.gps_map_data)) {
          let item = {
            ...person,
            position: {
              lat: Number(person.gps_map_data.coordinate.lat),
              lng: Number(person.gps_map_data.coordinate.lng),
            },
          };
          this.listTargetPersonWithMarker.push(item);
        }

        if (!this.checkEmpty(person.over_seas_data)) {
          let item = {
            ...person,
            position: {
              lat: Number(person.over_seas_data.coordinate.lat),
              lng: Number(person.over_seas_data.coordinate.lng),
            },
          };
          this.listTargetPersonWithMarker.push(item);
        }

        if (!this.checkEmpty(person.schedule_data)) {
          person.schedule_data.forEach(schedule => {
            if (!this.checkEmpty(schedule.arrival_coordinate)) {
              let item = {
                ...person,
                position: {
                  lat: Number(schedule.arrival_coordinate.lat),
                  lng: Number(schedule.arrival_coordinate.lng),
                },
              };
              this.listTargetPersonWithMarker.push(item);
            }
            if (!this.checkEmpty(schedule.departure_coordinate)) {
              let item = {
                ...person,
                position: {
                  lat: Number(schedule.departure_coordinate.lat),
                  lng: Number(schedule.departure_coordinate.lng),
                },
              };
              this.listTargetPersonWithMarker.push(item);
            }
          });
        }
      });
      this.listCountry = [];
      this.listMembers.forEach(member => {
        if (!this.checkEmpty(member.countries)) {
          member.countries.forEach(ctr => {
            this.listCountry.push(ctr.id);
          });
        }
      });
    },
    listCountry() {
      let a = [],
        b = [],
        prev;
      let listCtr = this.cloneObject(this.listCountry);

      listCtr.sort();
      for (var i = 0; i < listCtr.length; i++) {
        if (listCtr[i] !== prev) {
          a.push(listCtr[i]);
          b.push(1);
        } else {
          b[b.length - 1]++;
        }
        prev = listCtr[i];
      }

      let countryIndex = 0;
      let maxId = 0;
      if (!this.checkEmpty(b)) {
        b.forEach((i, index) => {
          if (i >= maxId) {
            maxId = i;
            countryIndex = index;
          }
        });
      }

      this.listMembers.forEach(member => {
        if (!this.checkEmpty(member.countries)) {
          member.countries.forEach(ctr => {
            if (ctr.id == a[countryIndex]) this.ctrTarget = ctr;
          });
        }
      });
    },
    ctrTarget(val) {
      if (!this.checkEmpty(val)) {
        this.$services.common.searchTimeZones(
          { address: this.labelLocale(val) + '' },
          res => {
            this.targetCountry = {
              lat: res.lat,
              lng: res.lng,
            };
          },
          () => {},
        );
      }
    },
  },
  methods: {
    getDefaultCenterMap() {
      if (this.companyAddress) {
        this.$services.common.searchTimeZones(
          { address: this.companyAddress },
          res => {
            if (res) this.setCenterMap(res);
          },
          () => {},
        );
      }
    },
    setCenterMap(data) {
      if (data.lat & data.lng) {
        this.center = {
          lat: +data.lat + Math.random() * 0.00001,
          lng: +data.lng,
        };
      }
    },
    sendBroadcastToSingleUser() {
      this.startLoading();
      this.$services.security.sendBroadcastToSingleUser(
        this.selectedMember.id,
        () => {
          this.endLoading();
          this.notifySuccess(this.$t('E-CU-020'));
        },
        err => {
          this.endLoading();
          this.notifyError(this.$t(err.error));
        },
      );
    },
  },
};
</script>
<style scoped>
/* div:has(.marker) {
  left: -30px !important;
  right: -70px;
} */
.security--map--people {
  top: -70px;
  left: -30px;
}
</style>
