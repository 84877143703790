<template>
  <nav class="navbar navbar-default navbar-lg" role="navigation" id="header-content">
    <div class="navbar-header">
      <h2 class="navbar-title">{{ $t('customer.company') }}</h2>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right">
        <li class="active">
          <a>{{ $t('customer.basic_information') }}</a>
        </li>
      </ul>
    </div>
  </nav>
  <div>
    <div class="section noborder">
      <div class="row">
        <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="form-group required viewonly">
            <label>
              <span class="label-text">{{ $t('customer.company_id') }}</span>
            </label>
            <input class="form-control input-sm" id="customer-id__view" maxlength="50" v-model="dataRequest.code" />
            <p v-text="errors.code" class="error-feedback" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="form-group required show--edit show--new hide--view">
            <label>
              <span class="label-text">{{ $t('customer.company_name') }}</span>
            </label>
            <input
                type="text"
                class="form-control input-sm"
                placeholder=""
                maxlength="100"
                id="customer-name"
                v-model="dataRequest.name"
            />
            <p v-text="errors.name" class="error-feedback" as="p" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6 col-sm-3 col-md-2 col-all-closer col-all-closer--leftend">
          <div class="form-group required">
            <label>
              <span class="label-text">{{ $t('customer.location') }}</span>
            </label>
            <input
                id="zip-code"
                class="form-control input-sm js-zip-code"
                type="text"
                :placeholder="$t('customer.zip_code_placeholder')"
                maxlength="10"
                v-model="dataRequest.zip_code"
            />
            <p v-text="errors.zip_code" class="error-feedback" as="p" />
          </div>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-3 col-all-closer col-all-closer--rightend">
          <div class="form-group">
            <button
                id="zip-btn"
                class="btn btn-sm btn-inverse-primary js-btn--feedback-changed js-zip-code-btn btn--ls"
                @click="getAddressFromZipCode"
            >
              <span class="icon-abui-write prx" />{{ $t('customer.from_zip_code') }}
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 col-md-2 col-sm-closer col-all-closer--leftend">
          <div class="form-group js-btn--feedback-changed__tgt-01">
            <label class="sr-only">{{ $t('customer.prefecture') }}</label>
            <div class="btn-group bootstrap-select select-block select-sm js-select-item01 js-prefecture">
              <SingleGroupOptionSelectCity
                  :init-item="dataRequest.prefecture_id"
                  :is-display-search="false"
                  :list-data="listCity"
                  @handle-selected-params="onChangeCity"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-5 col-sm-closer">
          <div class="form-group js-btn--feedback-changed__tgt-02">
            <label class="sr-only">{{ $t('customer.address_1') }}</label>
            <input
                type="text"
                id="municipality"
                class="form-control input-sm js-municipality"
                v-model="dataRequest.district"
                maxlength="100"
                :placeholder="$t('customer.municipalities')"
            />
            <p v-text="errors.district" class="error-feedback" as="p" />
          </div>
        </div>
        <div class="col-sm-4 col-md-5 col-sm-closer col-all-closer--rightend">
          <div class="form-group js-btn--feedback-changed__tgt-03">
            <label class="sr-only">{{ $t('customer.address_2') }}</label>
            <input
                type="text"
                class="form-control input-sm js-address--01"
                v-model="dataRequest.address"
                maxlength="100"
                :placeholder="$t('customer.address')"
            />
            <p v-text="errors.address" class="error-feedback" as="p" />
          </div>
        </div>
        <div class="col-sm-12 col-md-12">
          <div class="form-group">
            <label class="sr-only">{{ $t('customer.address_3') }}</label>
            <input
                type="text"
                class="form-control input-sm js-address--02"
                v-model="dataRequest.building"
                maxlength="100"
                :placeholder="$t('customer.building')"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-4">
          <div class="form-group required">
            <label>
              <span class="label-text">{{ $t('customer.telephone') }}</span>
            </label>
            <input
                type="text"
                class="form-control input-sm"
                v-model="dataRequest.phone_number"
                :placeholder="$t('customer.phone_placeholder')"
                id="company-phone"
                maxlength="13"
            />
            <p v-text="errors.phone_number" class="error-feedback" as="p" />
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4">
          <div class="form-group required">
            <label
            ><span class="label-text">{{ $t('customer.email') }}</span></label
            >
            <input
                type="text"
                class="form-control input-sm"
                v-model="dataRequest.email"
                placeholder="info@sample.com"
                id="company-mail"
                maxlength="100"
            />
            <p v-text="errors.email" class="error-feedback" as="p" />
          </div>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4">
          <div class="form-group required">
            <label>
              <span class="label-text">{{ $t('customer.first_password') }}</span>
            </label>
            <input
                type="text"
                class="form-control input-sm"
                v-model="dataRequest.first_password"
                :placeholder="$t('customer.first_password')"
                maxlength="50"
            />
            <p v-text="errors.first_password" class="error-feedback" as="p" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group show--edit show--new hide--view">
            <label>
              <span class="label-text">{{ $t('customer.remark') }}</span>
            </label>
            <textarea class="form-control" rows="6" placeholder="" v-model="dataRequest.note" />
          </div>
        </div>
      </div>
    </div>
    <div data-v-369b90fa="">
      <div class="section"><h4 class="section--title"> {{ $t('trip.trip') }}</h4>
        <div class="js-switch_item02-01__tgt">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group" v-if="dataRequest.setting">
                <label
                    class="checkbox checkbox-sm checkbox-inline"
                    :class="dataRequest.setting.is_upload_itinerary && 'checked'"
                >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"/>
                  <span class="second-icon icon-abui-checkbox-checked"/>
                </span>
                  <input type="checkbox" v-model="dataRequest.setting.is_upload_itinerary" id=""/>
                  <span style="margin-left: 32px">
              {{ $t('customer.is_upload_itinerary') }}
                </span>
                </label>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div class="js-switch_item02-01__tgt">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group" v-if="dataRequest.setting">
                <label
                    class="checkbox checkbox-sm checkbox-inline"
                    :class="dataRequest.setting.is_show_change_status_request && 'checked'"
                >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"/>
                  <span class="second-icon icon-abui-checkbox-checked"/>
                </span>
                  <input type="checkbox" v-model="dataRequest.setting.is_show_change_status_request" id=""/>
                  <span style="margin-left: 32px">
                  {{ $t('customer.is_show_change_status_request') }}
                </span>
                </label>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div class="js-switch_item02-01__tgt">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group" v-if="dataRequest.setting">
                <label
                    class="checkbox checkbox-sm checkbox-inline"
                    :class="dataRequest.setting.is_send_notification_ciq && 'checked'"
                >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"/>
                  <span class="second-icon icon-abui-checkbox-checked"/>
                </span>
                  <input type="checkbox" v-model="dataRequest.setting.is_send_notification_ciq" id=""/>
                  <span style="margin-left: 32px">
                           {{ $t('customer.is_send_notification_ciq') }}
                </span>
                </label>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div class="js-switch_item02-01__tgt">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group" v-if="dataRequest.setting">
                <label
                    class="checkbox checkbox-sm checkbox-inline"
                    :class="dataRequest.setting.is_need_schedule_hotel && 'checked'"
                >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"/>
                  <span class="second-icon icon-abui-checkbox-checked"/>
                </span>
                  <input type="checkbox" v-model="dataRequest.setting.is_need_schedule_hotel" id=""/>
                  <span style="margin-left: 32px">
                           {{ $t('customer.is_need_schedule_hotel') }}
                </span>
                </label>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <h4 class="section--title"> {{ $t('customer.is_show_approval') }}</h4>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group js-switch_item" v-if="dataRequest.setting" id="02-01">
              <span class="label-additional js-label-tgl-swt"/>
              <div
                  class="js-tgl-swt js-tgl-swt--rnd has-switch"
                  @click="dataRequest.setting.is_show_approval = !dataRequest.setting.is_show_approval"
              >
                <div class="switch-animate"
                     :class="dataRequest.setting.is_show_approval ? 'switch-on' : 'switch-off'">
                  <span class="switch-left">{{ $t('customer.on') }}</span>
                  <label>&nbsp;</label>
                  <span class="switch-right">{{ $t('customer.off') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section" v-show="dataRequest.setting.is_show_approval">
        <h4 class="section--title"> {{ $t('customer.is_show_report') }}</h4>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group js-switch_item" v-if="dataRequest.setting" id="02-021">
              <span class="label-additional js-label-tgl-swt"/>
              <div
                  class="js-tgl-swt js-tgl-swt--rnd has-switch"
                  @click="dataRequest.setting.is_show_report = !dataRequest.setting.is_show_report"
              >
                <div class="switch-animate"
                     :class="dataRequest.setting.is_show_report ? 'switch-on' : 'switch-off'">
                  <span class="switch-left">{{ $t('customer.on') }}</span>
                  <label>&nbsp;</label>
                  <span class="switch-right">{{ $t('customer.off') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <h4 class="section--title">{{ $t('customer.cost_reimbursement') }}</h4>
        <p>
          {{ $t('customer.note_on_off') }}
        </p>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group js-switch_item" v-if="dataRequest.setting" id="02-01">
              <span class="label-additional js-label-tgl-swt"/>
              <div
                  class="js-tgl-swt js-tgl-swt--rnd has-switch"
                  @click="dataRequest.setting.is_feature_payment = !dataRequest.setting.is_feature_payment"
              >
                <div class="switch-animate" :class="dataRequest.setting.is_feature_payment ? 'switch-on' : 'switch-off'">
                  <span class="switch-left">{{ $t('customer.on') }}</span>
                  <label>&nbsp;</label>
                  <span class="switch-right">{{ $t('customer.off') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="js-switch_item02-01__tgt">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label
                    class="checkbox checkbox-sm checkbox-inline"
                    :class="dataRequest.setting.is_upload_invoice && 'checked'"
                >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"/>
                  <span class="second-icon icon-abui-checkbox-checked"/>
                </span>
                  <input type="checkbox" v-model="dataRequest.setting.is_upload_invoice" id=""/>
                  <span style="margin-left: 32px">
                  {{ $t('customer.upload_voucher') }}
                </span>
                </label>
                <p></p></div>
            </div>
          </div>
        </div>
        <div class="js-switch_item02-01__tgt">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group" v-if="dataRequest.setting">
                <label
                    class="checkbox checkbox-sm checkbox-inline"
                    :class="dataRequest.setting.is_upload_receipt_cost  && 'checked'"
                >
                <span class="icons">
                  <span class="first-icon icon-abui-checkbox-unchecked"/>
                  <span class="second-icon icon-abui-checkbox-checked"/>
                </span>
                  <input type="checkbox" v-model="dataRequest.setting.is_upload_receipt_cost " id=""/>
                  <span style="margin-left: 32px">
                       {{ $t('customer.is_upload_receipt_cost') }}
             </span>
                </label>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <h4 class="section--title"> {{ $t('budget.title_top') }}</h4>
        <p>
          {{ $t('customer.is_show_setting_budget') }}
        </p>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group js-switch_item" v-if="dataRequest.setting" id="02-01">
              <span class="label-additional js-label-tgl-swt"/>
              <div
                  class="js-tgl-swt js-tgl-swt--rnd has-switch"
                  @click="dataRequest.setting.is_show_setting_budget = !dataRequest.setting.is_show_setting_budget"
              >
                <div class="switch-animate"
                     :class="dataRequest.setting.is_show_setting_budget ? 'switch-on' : 'switch-off'">
                  <span class="switch-left">{{ $t('customer.on') }}</span>
                  <label>&nbsp;</label>
                  <span class="switch-right">{{ $t('customer.off') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <h4 class="section--title"> {{ $t('customer.is_show_kikikanri') }}</h4>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group js-switch_item" v-if="dataRequest.setting" id="02-01">
            <span class="label-additional js-label-tgl-swt"/>
            <div
                class="js-tgl-swt js-tgl-swt--rnd has-switch"
                @click="dataRequest.setting.is_show_kikikanri = !dataRequest.setting.is_show_kikikanri"
            >
              <div class="switch-animate"
                    :class="dataRequest.setting.is_show_kikikanri ? 'switch-on' : 'switch-off'">
                <span class="switch-left">{{ $t('customer.on') }}</span>
                <label>&nbsp;</label>
                <span class="switch-right">{{ $t('customer.off') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <h4 class="section--title"> {{ $t('customer.is_show_daily_allowance') }}</h4>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group js-switch_item" v-if="dataRequest.setting" id="05-01">
            <span class="label-additional js-label-tgl-swt"/>
            <div
                class="js-tgl-swt js-tgl-swt--rnd has-switch"
                @click="dataRequest.setting.is_show_daily_allowance = !dataRequest.setting.is_show_daily_allowance"
            >
              <div class="switch-animate"
                    :class="dataRequest.setting.is_show_daily_allowance ? 'switch-on' : 'switch-off'">
                <span class="switch-left">{{ $t('customer.on') }}</span>
                <label>&nbsp;</label>
                <span class="switch-right">{{ $t('customer.off') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <h4 class="section--title"> {{ $t('customer.is_show_expense_item') }}</h4>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group js-switch_item" v-if="dataRequest.setting" id="05-02">
            <span class="label-additional js-label-tgl-swt"/>
            <div
                class="js-tgl-swt js-tgl-swt--rnd has-switch"
                @click="dataRequest.setting.is_show_expense_item = !dataRequest.setting.is_show_expense_item"
            >
              <div class="switch-animate"
                    :class="dataRequest.setting.is_show_expense_item ? 'switch-on' : 'switch-off'">
                <span class="switch-left">{{ $t('customer.on') }}</span>
                <label>&nbsp;</label>
                <span class="switch-right">{{ $t('customer.off') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="row">
        <div class="col-sm-3 col-md-2 hidden-xs">
          <a class="btn btn-sm btn-flat-default js-btn--back" @click="dialogVisibleStatus = true">
            <span class="icon-abui-arrow-left prx" />{{ $t('customer.back_to_list') }}
          </a>
        </div>
        <div class="col-sm-3 col-md-2 col-sm-offset-6 col-md-offset-8 show--edit show--new hide--view">
          <a class="btn btn-sm btn-primary btn-block open-alert js-btn--save alertAfterAnother mbl" @click="validate">
            {{ $t('customer.save_customer_company') }}
          </a>
        </div>
      </div>
    </div>

    <el-dialog
        v-model="dialogVisibleStatus"
        :show-close="false"
        @close="dialogVisibleStatus = false"
        width="640px"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
    >
      <div class="modal--2fa">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <div class="modal-header--title"></div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <p class="modal--diaglog--text text-center">
                    {{ $t('customer.save_and_move_confirm') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6">
                  <a
                      class="btn btn-sm btn-flat-default btn-block js--btn-do-move open-alert alertAfterAnother"
                      @click="dialogVisibleStatus = false"
                  >
                    {{ $t('common.cancel') }}
                  </a>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6">
                  <a
                      class="btn btn-sm btn-flat-default btn-block js--btn-do-move open-alert alertAfterAnother"
                      @click="
                      () => {
                        this.isCommit;
                        this.$router.push(this.PAGES.CUSTOMER_ENTERPRISE);
                      }
                    "
                  >
                    {{ $t('common.yes') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import SingleGroupOptionSelectCity from '@/components/customer-enterprise/SingleGroupOptionSelectCity.vue';
import { ElDialog } from 'element-plus';

export default {
  name: 'New',
  components: { SingleGroupOptionSelectCity, ElDialog },
  data() {
    return {
      listCity: [],
      dataRequest: {
        code: '',
        name: '',
        email: '',
        zip_code: '',
        building: '',
        phone_number: '',
        district: '',
        address: '',
        note: '',
        setting: {
          is_feature_payment: 1,
          is_upload_invoice: 0,
          is_upload_receipt_cost: 0,
          is_show_setting_budget: 0,
          is_upload_itinerary: 0,
          is_show_change_status_request: 0,
          is__setting_budget: 0,
          is_show_approval: 1,
          is_show_report: 1,
          is_show_kikikanri: 1,
          is_show_daily_allowance: 1,
          is_show_expense_item: 1,
          is_send_notification_ciq: 0,
          is_need_schedule_hotel: 1
        },
        city: null,
        prefecture_id: null,
      },
      dataRequestOfficial: {
        code: '',
        name: '',
        email: '',
        zip_code: '',
        building: '',
        phone_number: '',
        district: '',
        address: '',
        note: '',
        setting: {
          is_feature_payment: 0,
          is_upload_invoice: 0,
          is_upload_receipt_cost: 0,
          is_show_setting_budget: 0,
          is_upload_itinerary: 0,
          is_show_change_status_request: 0,
          is__setting_budget: 0,
          is_show_approval: 1,
          is_show_kikikanri: 1,
          is_send_notification_ciq: 0,
          is_need_schedule_hotel: 1
        },
        city: null,
        prefecture_id: null,
      },
      dialogVisibleStatus: false,
      errors: {
        code: '',
        name: '',
        zip_code: '',
        phone_number: '',
        email: '',
        first_password: '',
        district: '',
        address: '',
      },
      isCommit: false,
    };
  },
  created() {
    this.getListCity();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.compareObject(this.dataRequest, this.dataRequestOfficial) && !this.isCommit) {
      const answer = window.confirm(this.$t('common.confirm_leave_page'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    async getListCity() {
      this.startLoading();
      await this.$services.common.getListProvinces(
          res => {
            this.endLoading();
            this.listCity = res.list;
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    getAddressFromZipCode() {
      let flagValidate = true;
      if (this.checkEmpty(this.dataRequest.zip_code)) {
        flagValidate = false;
        this.errors.zip_code = this.$t('E-CM-100', { item: this.$t('customer.location') });
      } else if (!this.isZipCode(this.dataRequest.zip_code)) {
        flagValidate = false;
        this.errors.zip_code = this.$t('E-CM-101', { name: this.$t('customer.location') });
      } else if (this.strLen(this.dataRequest.zip_code) > 10) {
        flagValidate = false;
        this.errors.zip_code = this.$t('E-CM-111', { size: 10 });
      } else {
        this.errors.zip_code = '';
      }

      if (!flagValidate) return;

      this.startLoading();
      this.$services.common.getAddressByZipCode(
          this.dataRequest.zip_code,
          res => {
            this.listCity.forEach(city => {
              if (!this.checkEmpty(res.province) && this.labelLocale(city) == res.province) {
                this.dataRequest.prefecture_id = city.id;
                this.dataRequest.district = res.city_name + res.district;
                this.dataRequest.address = res.street;
              } else if (!this.checkEmpty(res.city_name) && this.labelLocale(city) == res.city_name) {
                this.dataRequest.prefecture_id = city.id;
                this.dataRequest.district = res.district;
                this.dataRequest.address = res.street;
              }
            });
            this.endLoading();
          },
          err => {
            this.endLoading();
            this.notifyError(this.$t(err.error));
          },
      );
    },
    onChangeCity(id) {
      if (!this.checkEmpty(id)) {
        this.dataRequest.prefecture_id = id;
      }
    },
    validate() {
      let flagValidate = true;
      const halfWidthRegex = /^[\x20-\x7E]*$/;

      if (this.checkEmpty(this.dataRequest.code)) {
        flagValidate = false;
        this.errors.code = this.$t('E-CM-100', { item: this.$t('customer.company_id') });
      } else if (this.strLen(this.dataRequest.code) > 100) {
        flagValidate = false;
        this.errors.code = this.$t('E-CM-111', { size: 100 });
      } else if (!halfWidthRegex.test(this.dataRequest.code)) {
        flagValidate = false;
        this.errors.code = this.$t('E-CM-120', { item: this.$t('customer.company_id') });
      } else {
        this.errors.code = '';
      }

      if (this.checkEmpty(this.dataRequest.name)) {
        flagValidate = false;
        this.errors.name = this.$t('E-CM-100', { item: this.$t('customer.company_name') });
      } else if (this.strLen(this.dataRequest.name) > 100) {
        flagValidate = false;
        this.errors.name = this.$t('E-CM-111', { size: 100 });
      } else {
        this.errors.name = '';
      }

      if (this.checkEmpty(this.dataRequest.zip_code)) {
        flagValidate = false;
        this.errors.zip_code = this.$t('E-CM-100', { item: this.$t('customer.location') });
      } else if (!this.isZipCode(this.dataRequest.zip_code)) {
        flagValidate = false;
        this.errors.zip_code = this.$t('E-CM-101', { name: this.$t('customer.location') });
      } else if (this.strLen(this.dataRequest.zip_code) > 10) {
        flagValidate = false;
        this.errors.zip_code = this.$t('E-CM-111', { size: 10 });
      } else {
        this.errors.zip_code = '';
      }

      if (this.checkEmpty(this.dataRequest.phone_number)) {
        flagValidate = false;
        this.errors.phone_number = this.$t('E-CM-100', { item: this.$t('customer.telephone') });
      } else if (!this.isPhoneNumber(this.dataRequest.phone_number)) {
        flagValidate = false;
        this.errors.phone_number = this.$t('E-CM-101', { name: this.$t('customer.telephone') });
      } else if (this.strLen(this.dataRequest.phone_number) > 20) {
        flagValidate = false;
        this.errors.phone_number = this.$t('E-CM-111', { size: 20 });
      } else {
        this.errors.phone_number = '';
      }

      if (this.checkEmpty(this.dataRequest.email)) {
        flagValidate = false;
        this.errors.email = this.$t('E-CM-100', { item: this.$t('customer.email') });
      } else if (!this.isEmail(this.dataRequest.email)) {
        flagValidate = false;
        this.errors.email = this.$t('E-CM-101', { name: this.$t('customer.email') });
      } else if (this.strLen(this.dataRequest.email) > 100) {
        flagValidate = false;
        this.errors.email = this.$t('E-CM-111', { size: 100 });
      } else {
        this.errors.email = '';
      }

      if (this.checkEmpty(this.dataRequest.first_password)) {
        flagValidate = false;
        this.errors.first_password = this.$t('E-CM-100', { item: this.$t('customer.first_password') });
      } else {
        this.errors.first_password = '';
      }

      if (this.checkEmpty(this.dataRequest.district)) {
        flagValidate = false;
        this.errors.district = this.$t('E-CM-100', { item: this.$t('customer.municipalities') });
      } else {
        this.errors.district = '';
      }

      if (this.checkEmpty(this.dataRequest.address)) {
        flagValidate = false;
        this.errors.address = this.$t('E-CM-100', { item: this.$t('customer.address') });
      } else {
        this.errors.address = '';
      }

      this.dataRequest.province_id = this.dataRequest.prefecture_id;

      if (!flagValidate) return;

      this.createCompany();
    },
    createCompany() {
      this.isCommit = true;
      this.$services.customer_enterprise.createCompany(
          this.dataRequest,
          () => {
            // if (!this.checkEmpty(res) && res.flagHaveIdInSB) {
            //   this.notifySuccess(this.$t('customer.importing_from_sb'), true);
            // } else {
            this.notifySuccess(this.$t('I-CM-103'));
            // }

            this.$router.push(this.PAGES.CUSTOMER_ENTERPRISE);
          },
          err => {
            if (err?.error?.code) {
              this.errors = {
                ...this.errors,
                code: this.$t(err?.error?.code[0], { item: this.$t('customer.company_id') }),
              };
            }
            this.notifyError(this.$t(err.error));
          },
      );
    },
  },
};
</script>
